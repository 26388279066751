.cmp-experiencefragment--footer {
  background-color: var(--emu-semantic-colors-secondary-grey-100);
  padding-top: var(--emu-common-spacing-xl);
  padding-bottom: var(--emu-common-spacing-large);

  .aaaem-container {
    background-color: var(--emu-semantic-colors-none);
  }

  .footer {
    &__top-section,
    &__bottom-section {
      width: var(--emu-semantic-sizing-breakpoints-large);
      max-width: 95%;
      margin-left: auto;
      margin-right: auto;
    }

    &__top-section {
      padding-bottom: 20px;

      .aaaem-image {
        max-width: 125px;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;

        @include mq('medium') {
          margin-bottom: var(--emu-common-spacing-none);
          max-width: max-content;
          width: 180px;
        }

        img {
          width: 100%;
        }
      }

      .aaaem-text {
        font-size: 14px;
        line-height: 20px;

        ul {
          padding-left: var(--emu-common-spacing-none);
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);
          // live uses image and anchors as siblings. But that cannot be done in authoring. So adding a calculation to facilitate approximate spacing between logo and links
          margin-left: min(50px, 2vw);
        }

        li {
          list-style: none;
          margin: var(--emu-common-spacing-none);
          text-align: center;
          padding-left: 5px;
          padding-right: 5px;

          a {
            font-weight: var(--emu-semantic-font-weight-extra-500);
            color: var(--emu-semantic-colors-secondary-grey-200);
            text-decoration: none;
          }
        }
      }

      @include mq('medium') {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-left: 20px;

        .text {
          width: 100%;

          .aaaem-text {
            line-height: 14px;

            ul {
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              li {
                text-align: left;
              }
            }
          }
        }
      }

      @include mq('large') {
        margin-left: auto;
      }
    }

    &__bottom-section {
      width: 1026px; // needed to match the designs

      .aaaem-image {
        max-width: 200px;
        width: 100%;
      }

      .aaaem-text {
        font-size: var(--emu-common-font-sizes-narrow-medium);
        line-height: 15px;
        color: var(--emu-semantic-colors-secondary-grey-800);

        a {
          color: #337ab7; // used only once, no variable
          text-decoration: none;

          &:hover,
          &:focus,
          &:active {
            color: #23527c; // used only once, no variable
            text-decoration: underline;
          }
        }

        @include mq('medium') {
          font-size: var(--emu-common-font-sizes-wide-medium);
          line-height: 20px;
        }

        p {
          margin: var(--emu-common-spacing-none);
          font-weight: var(--emu-semantic-font-weight-extra-500);
        }
      }
    }
  }
}
