.header {
  width: 100%;
  opacity: 0.9;

  @include mq('medium') {
    opacity: 1;
  }

  &__content {
    position: relative;
    z-index: var(--emu-common-other-z-index-header);
  }

  &__eyebrow-container {
    display: flex;
    background-color: var(--emu-semantic-colors-secondary-grey-100);
    justify-content: center;
    position: relative;

    > div:first-child {
      width: 100%;
    }

    .header__brand-menu-trigger {
      font-size: 10.5px;
      line-height: var(--emu-semantic-line-heights-narrow-large);
      font-weight: var(--emu-semantic-font-weight-extra-600);
      &,
      &:active,
      &:focus,
      &:hover {
        span {
          color: var(--emu-semantic-colors-primary-blue-200);
        }
      }
    }
  }

  &__main-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: inherit;
    padding-top: var(--emu-common-spacing-small);
    padding-bottom: var(--emu-common-spacing-small);
    padding-left: 15px;
    padding-right: 15px;

    &-logo {
      img {
        max-height: 45px;
        height: 100%;
      }
    }

    // hamburger icon
    .button {
      display: flex;
      margin-right: 5px;

      .aaaem-button,
      .aaaem-button:hover,
      .aaaem-button:focus,
      .aaaem-button:active {
        background-color: var(--emu-common-colors-transparent);
        border: none;
        padding: var(--emu-common-spacing-none);
        margin: var(--emu-common-spacing-none);
        position: relative;

        span {
          height: 3px; // needed to match live site. Hamburger line
          width: 20px;
          background-color: var(--emu-common-colors-white);
          margin-top: var(--emu-common-spacing-small);
          margin-bottom: var(--emu-common-spacing-small);

          &::after,
          &::before {
            content: '';
            position: absolute;
            width: inherit;
            height: inherit;
            background-color: inherit;
            left: 0;
          }

          &::after {
            bottom: 2px;
          }

          &::before {
            top: 2px;
          }
        }
      }
    }

    &-wrapper {
      position: relative;
      background-color: rgba(0, 155, 222, 0.9); // color used ony once

      > div:first-child {
        max-width: 695px; // matching the design
        width: 100%;
        margin-right: auto;
        margin-left: auto;

        .header__main-menu-wrapper-inner {
          background-color: var(--emu-common-colors-transparent);
        }
      }
    }
  }

  &__brand-menu {
    position: relative;
    background-color: inherit;
    display: flex;
    justify-content: center;

    .aaaem-text {
      background-color: var(--emu-semantic-colors-secondary-grey-1100);
      transition: max-height var(--emu-common-other-time-duration-instant)
        linear;
      position: absolute;
      top: 46px;
      overflow: hidden;
      width: 100%;
      left: 0;
      z-index: var(--emu-common-other-z-index-header);
      max-height: var(--emu-common-sizing-none);

      ul {
        padding: var(--emu-common-spacing-none);
        margin: var(--emu-common-spacing-none);

        li > a {
          font-size: 9.5px;
          line-height: 12px;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 20px;
          padding-right: 20px;
          font-weight: var(--emu-semantic-font-weight-extra-500);
          text-decoration: none;
          color: var(--emu-semantic-colors-secondary-grey-900);
          width: 100%;
          display: block;

          &:hover {
            background-color: var(--emu-semantic-colors-secondary-grey-1000);
          }
        }
      }
    }
  }

  // brand menu animation
  &__brand-menu-links-wrapper.js-toggle-on {
    max-height: 400px;
  }

  @include mq('medium') {
    &__main-menu {
      background-color: inherit;
      width: fit-content;
      padding: var(--emu-common-spacing-none);

      .button {
        display: none;
      }

      &-wrapper {
        &-inner {
          display: flex;
          align-items: center;
        }
      }

      &-logo {
        img {
          max-height: 55px;
        }
      }
    }

    .emu-navigation__content-wrapper nav {
      background-color: var(--emu-common-colors-transparent);
      margin-left: 30px;
    }

    &__eyebrow-container {
      > div:first-child {
        width: fit-content;
      }

      .aaaem-button {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 5px;
        white-space: nowrap;
      }

      &-desktop-links {
        display: flex;
        height: 100%;
        align-items: center;

        ul {
          display: flex;
          margin: var(--emu-common-spacing-none);
          padding: var(--emu-common-spacing-none);

          li {
            list-style: none;
            display: inherit;

            a {
              text-decoration: none;
              font-size: 10.5px;
              line-height: var(--emu-semantic-line-heights-narrow-large);
              padding-top: 15px;
              padding-bottom: 15px;
              padding-left: var(--emu-common-spacing-xxs);
              padding-right: var(--emu-common-spacing-xxs);
              font-weight: var(--emu-semantic-font-weight-extra-600);
              color: var(--emu-semantic-colors-secondary-grey-900);
              white-space: nowrap;
            }
          }

          > li:last-child {
            a {
              color: var(--emu-common-colors-white);
              background-color: var(--emu-semantic-colors-primary-green-200);
              padding-left: 14px;
              padding-right: 10px;
            }
          }
        }
      }
    }

    &__brand-menu {
      .aaaem-text {
        background-color: var(--emu-semantic-colors-secondary-grey-1100);
        top: 46px;
        min-width: 325px; // to match live site
      }
    }
  }

  // tablet
  @include mq('tablet') {
    &__eyebrow-container {
      &-desktop-links {
        ul {
          li {
            a {
              padding-top: 15px;
              padding-bottom: 15px;
              padding-left: var(--emu-common-spacing-medium);
              padding-right: var(--emu-common-spacing-medium);
            }
          }

          > li:last-child {
            a {
              padding-left: 35px;
              padding-right: 35px;
            }
          }
        }
      }

      .aaaem-button {
        padding-left: var(--emu-common-spacing-medium);
        padding-right: var(--emu-common-spacing-medium);
        margin-right: 5px;
      }
    }

    &__main-menu {
      &-logo {
        img {
          margin-left: 5px;
        }
      }
    }
  }
}

// hiding main menu in mobile, when brand menu is open
.has-brand-menu-open .header__main-menu-wrapper {
  visibility: hidden;

  @include mq('medium') {
    visibility: visible;
  }
}

// making sure that header comes on top of teaser
header.experiencefragment {
  position: relative;
  z-index: var(--emu-common-other-z-index-header);

  & + div {
    z-index: var(--emu-common-other-z-index-base);
  }
}
