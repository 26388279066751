.aaaem-accordion {
  max-width: 95%;
  width: 1135px; // needed to match design
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  &__header {
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top: var(--emu-common-border-width-thin) solid
      var(--emu-semantic-colors-primary-blue-100);
    margin-top: 5px;
    transition-delay: 0.35s;

    &.-ba-is-active {
      padding-bottom: var(--emu-common-spacing-medium);
      transition-delay: var(--emu-common-other-time-delay-none);

      .aaaem-accordion__icon {
        &::before {
          display: none;
        }

        &::after {
          width: 2px;
        }
      }

      @include mq('medium') {
        padding-bottom: var(--emu-common-spacing-large);
      }
    }
  }

  &__title {
    line-height: var(--emu-common-line-heights-narrow-large);
    font-weight: var(--emu-common-font-weight-bold);
    color: var(--emu-semantic-colors-primary-blue-100);
  }

  &__icon {
    top: -27px;
    right: -22px;

    &::before,
    &::after {
      transition: none;
    }
  }

  @include mq('medium') {
    &__title {
      line-height: 26px;
    }

    &__icon {
      top: -6px;
      right: 0;
      left: -4px;
      width: 20px; // needed to match design
      height: 20px; // needed to match design

      &::before,
      &::after {
        width: 4.5px; // too different if made a non-decimal number
      }
    }
  }
}

dd .js-badger-accordion-panel-inner {
  .aaaem-text {
    h3,
    p {
      margin-top: var(--emu-common-spacing-none);
      margin-bottom: var(--emu-common-spacing-none);
      font-size: var(--emu-semantic-font-sizes-narrow-medium);
      line-height: var(--emu-common-line-heights-wide-large);
    }

    h3 {
      font-weight: var(--emu-common-font-weight-bold);
    }

    p {
      padding-bottom: 24px;

      sup {
        font-size: 8px;
        position: relative;
        top: -6px;
      }

      .emphasis {
        display: block;
        font-size: var(--emu-common-font-sizes-narrow-medium);
        line-height: 15px;
      }

      a {
        color: var(--emu-semantic-colors-primary-blue-100);

        &:hover,
        &:focus,
        &:active {
          color: var(--emu-semantic-colors-primary-blue-400);
        }
      }
    }

    img {
      display: inline-block;
      vertical-align: top;
      position: relative;
      top: -5px;
      margin-right: var(--emu-common-spacing-xs);
    }

    @include mq('medium') {
      h3,
      p {
        font-size: var(--emu-semantic-font-sizes-wide-large);
      }

      p {
        .emphasis {
          font-size: var(--emu-common-font-sizes-wide-medium);
          line-height: var(--emu-common-line-heights-wide-large);
        }

        sup {
          font-size: 12px;
          top: -7px;
        }
      }
    }
  }
}
