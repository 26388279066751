.aaaem-card {
  &__title-wrapper {
    display: none;
  }

  &__body {
    [data-component='text'] {
      font-size: 20px;
      line-height: var(--emu-common-line-heights-wide-medium);
      margin-top: var(--emu-common-spacing-medium);
      margin-bottom: 3px;
      color: var(--emu-semantic-colors-secondary-grey-500);

      p {
        font-weight: var(--emu-semantic-font-weight-extra-500);
      }
    }
  }

  .button {
    display: flex;
    justify-content: center;

    .aaaem-button {
      padding-top: var(--emu-common-spacing-medium);
      padding-bottom: var(--emu-common-spacing-medium);
      margin-top: var(--emu-common-spacing-medium);
      max-width: 200px;
      width: 100%;

      span {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
