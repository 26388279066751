// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('medium') {
    display: block;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }
}

.mobile-view {
  display: block;

  @include mq('medium') {
    display: none;
  }
}

// padding adjustments
.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.container--small {
  width: 1000px;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;

  @include mq('medium') {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

.container--small-wrapper {
  padding-left: 15px;
  padding-right: 15px;

  @include mq('medium') {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }

  @include mq('tablet') {
    padding-left: 15px;
    padding-right: 15px;
  }

  .container--small {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

.container--large,
.container--root {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

[data-component='text'],
[data-component='title'] {
  &.text--color-blue {
    color: var(--emu-semantic-colors-primary-blue-100);
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: inherit;
    }
  }

  &.text--color-green {
    color: var(--emu-semantic-colors-primary-green-200);
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: inherit;
    }
  }
}
