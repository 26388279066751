#resources-page {
  p {
    margin: var(--emu-common-spacing-none);
  }

  .video-card--main {
    .video-card {
      &__bg {
        min-height: 250px;
        @include mq('medium') {
          min-height: var(--emu-common-sizing-none);
        }
      }
    }
  }

  .resources-card__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;

    @include mq('medium') {
      flex-direction: row;
      margin-left: auto;
      margin-right: auto;
      align-items: flex-end;
      padding-top: 87px;
    }

    @include mq('tablet') {
      max-width: 78%;
      width: 100%;
    }

    > .card {
      width: 100%;

      @include mq('medium') {
        width: 33.33%;

        &:first-child {
          .emu-card {
            @include mq('medium') {
              display: flex;
              justify-content: flex-end;
              flex-direction: column;
              align-items: center;
            }

            .button {
              min-width: 200px;
            }

            img {
              width: 100%;

              @include mq('medium') {
                width: 300px; // needed to match design
                position: relative;
                bottom: -14px;
                left: 23px;
                max-width: none;
              }

              @include mq('large') {
                min-width: 583px; // needed to match design
                bottom: -30px;
                left: -50px;
              }
            }
          }
        }
      }

      img {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .resources-video {
    &__bottom-section {
      @include mq('tablet') {
        display: flex;
        flex-direction: row;
        min-height: 280px;

        > div {
          width: 50%;
        }
      }

      > .container:last-child {
        .resources-video__container {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }
    }

    &__bottom-image {
      position: absolute;
      height: 122px; // as per live sute
      background-color: var(--emu-semantic-colors-none);
      background-position: 50% 100%;
      bottom: 9px;
      width: 100%;

      @include mq('medium') {
        bottom: 38px;
      }

      @include mq('tablet') {
        bottom: 48px;
      }

      @include mq('large') {
        bottom: 5px;
      }
    }

    &__section {
      margin-top: 40px;
      position: relative;
      padding-bottom: 40px;
      margin-bottom: 10px;

      @include mq('medium') {
        margin-bottom: var(--emu-common-spacing-none);
      }
    }
  }

  [data-id='resource-modal-two'],
  [data-id='resource-modal-three'] {
    .modal-content {
      @include mq('medium') {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
}
