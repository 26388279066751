[data-component='text'],
[data-component='title'] {
  color: var(--emu-semantic-colors-primary-purple-400);
  font: var(--emu-semantic-typography-narrow-body-regular);

  p,
  li {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
  }

  sup,
  sub {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -0.5em;
  }

  sub {
    top: -0.25em;
  }

  b {
    font-weight: var(--emu-common-font-weight-bold);
  }
}
