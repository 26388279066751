.cmp-teaser.has-bg-image.has-media {
  height: 420px; // needed to match live site
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: initial;
  margin-top: -60px; // to make the teaser go beneath the header

  @include mq('medium') {
    margin-top: -87px; // value from live site
    height: 475px; // needed to match live site
  }

  .cmp-teaser {
    &__title {
      font-size: 32px;
      line-height: 1;
      color: var(--emu-semantic-colors-primary-blue-100);
      font-weight: var(--emu-semantic-font-weight-extra-500);
      padding-top: var(--emu-common-spacing-xl);

      @include mq('medium') {
        font-size: 46px;
        line-height: 51px;
        padding-top: 208px;
      }
    }
  }
}
