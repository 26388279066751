#faq-page {
  .cmp-teaser {
    background-position: 62% 100%;
    margin-top: -75px;
    margin-bottom: 75px;

    @include mq('medium') {
      height: 405px;
      background-position: 50% 100%;
    }

    &__title {
      padding-top: 160px;

      @include mq('medium') {
        padding-top: 80px;
      }
    }
  }

  p {
    margin: var(--emu-common-sizing-none);
  }

  .faq-reference {
    width: var(--emu-semantic-sizing-breakpoints-large);
    max-width: 95%;
    padding-top: var(--emu-common-spacing-xl);
    padding-bottom: 48px;
    margin-left: auto;
    margin-right: auto;

    p {
      font-size: var(--emu-common-font-sizes-narrow-medium);
      line-height: var(--emu-semantic-line-heights-narrow-large);
      font-weight: var(--emu-semantic-font-weight-extra-500);
      color: rgba(70, 70, 70, 0.8); // as per live site - Used only once.
    }

    @include mq('medium') {
      padding-top: 112px;
      padding-bottom: 96px;

      p {
        font-size: var(--emu-common-font-sizes-wide-medium);
        line-height: var(--emu-semantic-line-heights-narrow-large);
      }
    }
  }
}
