@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-Black.woff') format('woff');
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-BlackItalic.woff') format('woff');
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-Bold.woff') format('woff');
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-BoldItalic.woff') format('woff');
  font-display: swap;
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-Book.woff') format('woff');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-BookItalic.woff') format('woff');
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-Light.woff') format('woff');
  font-display: swap;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-LightItalic.woff') format('woff');
  font-display: swap;
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-Medium.woff') format('woff');
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-MediumItalic.woff') format('woff');
  font-display: swap;
  font-weight: 500;
  font-style: italic;
}
