#features-page {
  .cmp-teaser {
    height: 401px;

    @include mq('medium') {
      height: 475px;
    }

    &__title {
      padding-top: 112px;
      position: absolute;
      top: 80px;
      left: 0;
      width: 100%;

      @include mq('medium') {
        padding-top: 216px;
        font-size: 44px;
      }
    }

    &__teaser-section {
      position: relative;
      z-index: var(--emu-common-other-z-index-layer);
    }

    &__image-section {
      position: absolute;
      background: transparent;
      display: block;
      width: 170px; // value from live site
      margin-right: auto;
      margin-left: auto;
      left: auto;
      right: 6px;
      z-index: var(--emu-common-other-z-index-layer);
      top: 292px;

      @include mq('medium') {
        top: 344px;
        right: 23px;
      }

      @include mq('tablet') {
        top: 341px;
        right: 0;
      }

      @include mq('large') {
        top: 332px;
        right: -870px;
        left: 0;
      }

      @include mq('x-large') {
        right: auto;
        left: 50%;
        width: 1000px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        transform: translateX(-50%) translateX(-30px);
      }

      .cmp-image__image {
        width: 100px; // value from live site
        margin-left: auto;
        margin-right: auto;

        @include mq('medium') {
          width: 130px; // value from live site
        }
      }
    }

    &__teaser-content {
      text-transform: none;
      font-weight: normal;
      font-style: normal;

      p {
        margin-top: var(--emu-common-spacing-small);
        font-family: sans; // no var. Used only once in the site
        color: var(--emu-semantic-colors-secondary-grey-500);
        font-size: 13px;
        line-height: 13px;

        .emphasis {
          display: block;
          padding-top: var(--emu-common-spacing-xxs);
        }
      }
    }
  }

  .features {
    &__bg-container.aaaem-container {
      position: relative;
      min-height: 1400px; // value from live site
      overflow: visible;
      padding-bottom: 56px;
      background-color: var(--emu-semantic-colors-secondary-grey-1200);
      margin-bottom: -454px; // value from live site
      top: -454px; // value from live site
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      > div {
        width: 100%;
      }

      @include mq('medium') {
        margin-bottom: -415px; // value from live site
        top: -415px; // value from live site
      }

      @include mq('tablet') {
        margin-bottom: -340px; // value from live site
        top: -340px; // value from live site
      }
    }

    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: auto !important; // needed to override the inline styles form AEM sightly
      background-position: 51% 0;
      background-color: var(--emu-common-colors-transparent);

      @include mq('medium') {
        background-position: 50% 0;
      }

      &--bottom {
        background-position: 50% 100%;
        background-size: 100% !important; // needed to override the inline styles form AEM sightly
      }
    }

    &__main-content {
      .aaaem-container:not(.features__bg-container):not(.features__stat-wrapper) {
        background-color: var(--emu-common-colors-transparent);
      }
    }

    &__intro-container {
      position: relative;
      z-index: var(--emu-common-other-z-index-layer);
    }

    &__intro-container,
    &__bg-container {
      background-color: var(--emu-common-colors-transparent);

      .aaaem-container {
        background-color: var(--emu-common-colors-transparent);
      }
    }

    &__intro {
      &-text {
        h2,
        h3 {
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          line-height: 1;
          font-weight: var(--emu-semantic-font-weight-extra-500);

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-medium);
          }

          @include mq('tablet') {
            font-size: var(--emu-semantic-font-sizes-wide-large);
          }

          sup {
            font-size: 8px;
            line-height: 0;
            font-weight: var(--emu-common-font-weight-regular);
            position: relative;
            top: -6px;

            @include mq('medium') {
              font-size: 12px;
              top: -8px;
            }

            @include mq('tablet') {
              top: -6px;
            }

            u {
              font-size: 14px;
              top: 3px;
              position: relative;
              font-family: initial;
              text-decoration: none;

              @include mq('medium') {
                top: 1px;
              }
            }
          }
        }

        h2 {
          margin-top: 60px;

          @include mq('medium') {
            margin-top: 90px;
          }
        }

        h3 {
          font-weight: var(--emu-common-font-weight-bold);
          margin-top: var(--emu-common-spacing-large);
          margin-bottom: var(--emu-common-spacing-none);

          @include mq('medium') {
            margin-top: 48px;
          }
        }
      }

      &-grid {
        &-item {
          display: flex;
          margin-top: var(--emu-common-spacing-medium);
          margin-left: var(--emu-common-spacing-medium);
          gap: var(--emu-common-spacing-medium);
          align-items: center;

          @include mq('medium') {
            margin-left: var(--emu-common-spacing-large);
          }

          > .image {
            width: 50px; // value from live site

            @include mq('medium') {
              width: 60px; // value from live site
            }

            img {
              width: 100%;
            }
          }

          > .text {
            flex: 1;
          }
        }

        &-text {
          h4 {
            margin-top: var(--emu-common-spacing-none);
            margin-bottom: var(--emu-common-spacing-none);
            font-size: var(--emu-semantic-font-sizes-narrow-medium);
            line-height: 1;
            font-weight: var(--emu-semantic-font-weight-extra-500);

            @include mq('medium') {
              font-size: var(--emu-semantic-font-sizes-wide-medium);
            }

            @include mq('tablet') {
              font-size: var(--emu-semantic-font-sizes-wide-large);
            }
          }
        }
      }

      &-disclaimer {
        margin-top: 43px;

        p {
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);
          font-size: var(--emu-common-font-sizes-narrow-medium);
          line-height: var(--emu-semantic-line-heights-narrow-large);
          text-indent: -5px;
          font-weight: var(--emu-semantic-font-weight-extra-500);
          color: var(--emu-semantic-colors-secondary-grey-500);

          @include mq('medium') {
            font-size: 17.6px; // noticeable difference when rounded. Not rounding off
            line-height: 16px; // as per live site, font size is more than line height
            text-indent: -7px;
          }

          @include mq('tablet') {
            font-size: 19.2px; // noticeable difference when rounded. Not rounding off
          }

          sup {
            font-family: initial;
            font-size: 9px;
            line-height: 0; // intentional lineheight removal for this sup
            top: -4px;

            @include mq('medium') {
              font-size: 13px;
              left: -2px;
              top: -7px;
            }

            @include mq('tablet') {
              font-size: 14.4px; // noticeable difference when rounded. Not rounding off
              left: 0;
              top: -8px;
            }
          }
        }
      }
    }

    &__stat {
      &-wrapper {
        &--fast-insertion {
          background-color: var(--emu-semantic-colors-secondary-grey-1200);
          padding-top: var(--emu-common-spacing-xl);
          padding-bottom: 48px;
          top: -160px; // value from live site
          margin-bottom: -160px; // value from live site
          position: relative;
          background-position: 50% 0px;
          background-size: 100% !important; // needed to override the inline background properties added by AEM sightly

          @include mq('medium') {
            padding-top: 160px; // value from live site
            margin-bottom: -100px; // value from live site
            top: -100px; // value from live site
          }

          @include mq('tablet') {
            padding-top: 226px; // value from live site
            margin-bottom: -160px; // value from live site
            top: -160px; // value from live site
            padding-bottom: 34px;
          }
        }
      }

      &-container {
        position: relative;
        z-index: var(--emu-common-other-z-index-layer);

        &--surgeon-recommendation {
          top: 12px;
        }

        &--efficient-delivery {
          padding-bottom: 128px;
          z-index: var(--emu-common-other-z-index-overlay);

          @include mq('medium') {
            padding-top: 32px;
            padding-bottom: 48px;

            .features__stat-text p {
              padding-right: var(--emu-common-spacing-none);
            }
          }
        }
      }

      &-title {
        h4 {
          margin-top: 10px;
          margin-bottom: 34px;
          font-size: var(--emu-semantic-font-sizes-narrow-xl);
          line-height: var(--emu-semantic-line-heights-narrow-xl);
          color: var(--emu-semantic-colors-primary-blue-100);
          font-weight: var(--emu-semantic-font-weight-extra-500);

          @include mq('medium') {
            margin-top: 10px;
            margin-bottom: 50px;
            font-size: var(--emu-semantic-font-sizes-wide-xl);
            line-height: var(--emu-semantic-line-heights-wide-xl);
          }

          @include mq('tablet') {
            font-size: 36.8px; // cannot round off. Major different with round off
            line-height: 40.48px; // cannot round off. Major different with round off
          }
        }
      }

      &-text {
        p {
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          line-height: 1;
          color: var(--emu-semantic-colors-secondary-grey-500);
          font-weight: var(--emu-semantic-font-weight-extra-500);
          margin-bottom: var(--emu-common-spacing-large);

          @include mq('medium') {
            padding-right: 160px;
            font-size: var(--emu-semantic-font-sizes-wide-medium);
          }

          @include mq('tablet') {
            font-size: var(--emu-semantic-font-sizes-wide-large);
          }

          sup {
            font-size: 8px;
            top: -5px;
            font-weight: var(--emu-common-font-weight-regular);

            u {
              text-decoration: none;
              font-family: initial;
            }

            @include mq('medium') {
              font-size: 12px;
              top: -7px;
            }
          }
        }

        img {
          width: 80px; // value from live site
          display: inline-block;
          margin-right: var(--emu-common-spacing-small);

          @include mq('medium') {
            width: auto;
            vertical-align: middle;
            position: relative;
            top: -8px;
          }
        }
      }

      &-img-text-container {
        display: flex;
        align-items: center;
        margin-bottom: 45px;
        gap: 20px;
        background-color: var(--emu-common-colors-transparent);

        @include mq('medium') {
          width: 680px; // value from live site
          margin-top: 48px;
        }

        > .image {
          width: 140px; // value deduced from live site

          @include mq('medium') {
            width: 188px;
          }
        }

        > .text {
          flex: 1;
        }
      }

      &-img-text {
        p {
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          line-height: 1;
          color: var(--emu-semantic-colors-primary-green-200);
          font-weight: var(--emu-semantic-font-weight-extra-500);
          margin: var(--emu-common-spacing-none);

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-medium);
          }

          @include mq('tablet') {
            font-size: var(--emu-semantic-font-sizes-wide-large);
          }

          sup {
            font-size: 8px;
            top: -6px;
            font-weight: var(--emu-common-font-weight-regular);

            u {
              text-decoration: none;
              font-family: initial;
            }

            @include mq('medium') {
              font-size: 12px;
            }
          }
        }
      }

      &-disclaimer {
        p {
          font-size: var(--emu-common-font-sizes-narrow-medium);
          line-height: var(--emu-semantic-line-heights-narrow-large);
          font-weight: var(--emu-semantic-font-weight-extra-500);
          color: var(--emu-semantic-colors-secondary-grey-500);
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: 12px;
          text-indent: -5px;

          @include mq('medium') {
            font-size: 17.6px; // cannot round off. Noticeable difference
          }

          @include mq('tablet') {
            font-size: 19.2px; // cannot round off. Noticeable difference
          }

          sup {
            font-family: initial;
            font-size: 9px;

            @include mq('medium') {
              font-size: 13.2px; // cannot round off. Noticeable difference
              left: -2px;
              top: -8px;
            }

            @include mq('tablet') {
              font-size: 14.4px; // cannot round off. Noticeable difference
            }
          }
        }
      }
    }

    &__references {
      padding-top: 102px; // deduced from live site
      padding-bottom: 48px;
      width: 1024px; // value from live site
      max-width: 95%;
      margin-left: auto;
      margin-right: auto;

      @include mq('medium') {
        padding-top: 96px; // deduced from live site
        padding-bottom: 80px; // deduced from live site
      }

      p {
        font-weight: var(--emu-semantic-font-weight-extra-500);
        font-size: var(--emu-common-font-sizes-narrow-medium);
        line-height: 16.8px; // cannot round off. Noticeable difference
        color: rgba(
          70,
          70,
          70,
          0.8
        ); // no variable, used only once in this file

        @include mq('medium') {
          font-size: 16px;
          line-height: 1;
        }
      }

      b {
        font-weight: var(--emu-semantic-font-weight-bold);
      }
    }
  }
}
