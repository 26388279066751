.header__nav-wrapper {
  max-height: var(--emu-common-sizing-none);
  left: 0;
  transition: max-height var(--emu-common-other-time-duration-instant) linear;
  overflow: hidden;
  position: absolute;
  width: 100%;
  background-color: var(--emu-semantic-colors-secondary-grey-100);

  .emu-navigation__content-wrapper {
    position: static;

    .emu-navigation {
      ul {
        li {
          a {
            font-size: 10.5px;
            line-height: var(--emu-semantic-line-heights-narrow-large);
            font-weight: var(--emu-semantic-font-weight-extra-500);
          }
        }
      }
    }
  }

  .emu-navigation__item {
    &--active {
      a {
        &,
        &:hover,
        &:focus,
        &:active {
          color: var(--emu-semantic-colors-primary-green-200);
        }

        @include mq('medium') {
          &,
          &:hover,
          &:focus,
          &:active {
            color: var(--emu-common-colors-white);
            text-decoration: underline;
          }
        }
      }
    }
  }

  &-mobile-nav {
    ul {
      .emu-navigation__item--active {
        a {
          &,
          &:hover,
          &:active,
          &:focus {
            color: var(
              --emu-component-lists-navigation-banner-item-text-color-default-light
            );
          }
        }
      }

      > li:last-child {
        a,
        a:hover {
          background-color: var(--emu-semantic-colors-primary-green-200);
          color: var(--emu-common-colors-white);
        }
      }
    }
  }
}

// nav animation
.header__nav-wrapper.js-toggle-on {
  max-height: 400px; // to match live height
}

@include mq('medium') {
  .header__nav-wrapper {
    position: static;
    max-height: none;
    height: fit-content;
    background-color: var(--emu-common-colors-transparent);

    .emu-navigation {
      &__content-wrapper {
        .emu-navigation {
          ul {
            li {
              a {
                font-size: 15px;
                line-height: 16px;
                color: var(--emu-common-colors-white);
                &:active {
                  text-decoration: underline;
                }
              }
            }
          }
        }

        .header__nav-wrapper-mobile-nav {
          display: none;
        }
      }
    }
  }

  .header__nav-wrapper.js-toggle-on {
    max-height: none;
    height: auto;
  }
}
