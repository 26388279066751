
/**
 * ** 🚨 DO NOT EDIT THIS FILE DIRECTLY!
 * If you want to make edits make them in each appropriate token JSON file.
 */

$emu-semantic-colors-none: rgba(0,0,0,0) !default;
$emu-semantic-colors-surface-light: #FFFFFF !default;
$emu-semantic-colors-surface-dark: #000000 !default;
$emu-semantic-colors-surface-modal-backdrop: rgba(0, 0, 0, 0.5) !default;
$emu-semantic-colors-actions-primary-light: rgba(0,0,0,0) !default;
$emu-semantic-colors-actions-primary-dark: rgba(0,0,0,0) !default;
$emu-semantic-colors-actions-on-primary-light: #00b0c6 !default;
$emu-semantic-colors-actions-on-primary-dark: #FFFFFF !default;
$emu-semantic-colors-text-light: #54565b !default;
$emu-semantic-colors-text-dark: #FFFFFF !default;
$emu-semantic-colors-foreground-light: #FFFFFF !default;
$emu-semantic-colors-foreground-dark: #000000 !default;
$emu-semantic-colors-primary-blue-100: #1f9bde !default;
$emu-semantic-colors-primary-blue-200: #001450 !default;
$emu-semantic-colors-primary-blue-300: #19a5e1 !default;
$emu-semantic-colors-primary-blue-400: #23527c !default;
$emu-semantic-colors-primary-green-100: #00b0c6 !default;
$emu-semantic-colors-primary-green-200: #00adbb !default;
$emu-semantic-colors-secondary-grey-100: #f0f0f0 !default;
$emu-semantic-colors-secondary-grey-200: #686868 !default;
$emu-semantic-colors-secondary-grey-300: #63656a !default;
$emu-semantic-colors-secondary-grey-400: #6f6f73 !default;
$emu-semantic-colors-secondary-grey-500: #54565b !default;
$emu-semantic-colors-secondary-grey-600: #464646 !default;
$emu-semantic-colors-secondary-grey-700: #333 !default;
$emu-semantic-colors-secondary-grey-800: #303030 !default;
$emu-semantic-colors-secondary-grey-900: #65646a !default;
$emu-semantic-colors-secondary-grey-1000: #adadad !default;
$emu-semantic-colors-secondary-grey-1100: #ddd !default;
$emu-semantic-colors-secondary-grey-1200: #f5f5f5 !default;
$emu-semantic-font-weight-bold: 700 !default;
$emu-semantic-font-weight-light: 300 !default;
$emu-semantic-font-weight-black: 900 !default;
$emu-semantic-font-weight-regular: 400 !default;
$emu-semantic-font-weight-extra-500: 500 !default;
$emu-semantic-font-weight-extra-600: 600 !default;
$emu-semantic-font-families-body: 'Gotham' !default;
$emu-semantic-font-families-heading: 'Gotham' !default;
$emu-semantic-font-families-mono: 'Gotham' !default;
$emu-semantic-spacing-none: 0px !default;
$emu-semantic-spacing-horizontal: 0px !default;
$emu-semantic-spacing-vertical: 0px !default;
$emu-semantic-spacing-one-line-height-wide: 0px !default;
$emu-semantic-spacing-one-line-height-narrow: 0px !default;
$emu-semantic-font-sizes-narrow-medium: 16px !default;
$emu-semantic-font-sizes-narrow-large: 16px !default;
$emu-semantic-font-sizes-narrow-xl: 24px !default;
$emu-semantic-font-sizes-narrow-xxl: 28px !default;
$emu-semantic-font-sizes-narrow-xxxl: 62px !default;
$emu-semantic-font-sizes-wide-medium: 22.4px !default;
$emu-semantic-font-sizes-wide-large: 24px !default;
$emu-semantic-font-sizes-wide-xl: 33.6px !default;
$emu-semantic-font-sizes-wide-xxl: 41.6px !default;
$emu-semantic-font-sizes-wide-xxxl: 64px !default;
$emu-semantic-line-heights-narrow-large: 16px !default;
$emu-semantic-line-heights-narrow-medium: 28.8px !default;
$emu-semantic-line-heights-narrow-xl: 26.4px !default;
$emu-semantic-line-heights-narrow-xxl: 28px !default;
$emu-semantic-line-heights-narrow-xxxl: 64px !default;
$emu-semantic-line-heights-wide-large: 22.4px !default;
$emu-semantic-line-heights-wide-medium: 28.8px !default;
$emu-semantic-line-heights-wide-xl: 37px !default;
$emu-semantic-line-heights-wide-xxl: 41.6px !default;
$emu-semantic-line-heights-wide-xxxl: 80px !default;
$emu-semantic-border-radius-xs: 4px !default;
$emu-semantic-border-radius-small: 8px !default;
$emu-semantic-border-radius-medium: 16px !default;
$emu-semantic-border-radius-large: 32px !default;
$emu-semantic-border-radius-none: 0px !default;
$emu-semantic-border-width-thin: 1px !default;
$emu-semantic-border-width-medium: 2px !default;
$emu-semantic-border-width-thick: 4px !default;
$emu-semantic-border-width-none: 0px !default;
$emu-semantic-outline-width-thin: thin !default;
$emu-semantic-outline-width-medium: medium !default;
$emu-semantic-outline-width-thick: thick !default;
$emu-semantic-outline-width-unset: unset !default;
$emu-semantic-sizing-none: 0px !default;
$emu-semantic-sizing-xxs: 1px !default;
$emu-semantic-sizing-xs: 8px !default;
$emu-semantic-sizing-small: 16px !default;
$emu-semantic-sizing-medium: 32px !default;
$emu-semantic-sizing-large: 64px !default;
$emu-semantic-sizing-xl: 128px !default;
$emu-semantic-sizing-xxl: 256px !default;
$emu-semantic-sizing-breakpoints-small: 480px !default;
$emu-semantic-sizing-breakpoints-medium: 768px !default;
$emu-semantic-sizing-breakpoints-tablet: 992px !default;
$emu-semantic-sizing-breakpoints-large: 1025px !default;
$emu-semantic-sizing-breakpoints-x-large: 1280px !default;
$emu-semantic-sizing-breakpoints-xx-large: 1440px !default;
$emu-semantic-sizing-one-line-height-wide: 28.8px !default;
$emu-semantic-sizing-one-line-height-narrow: 28.8px !default;
$emu-semantic-typography-narrow-headings-xxxl: 400 62px/64px 'Gotham' !default;
$emu-semantic-typography-narrow-headings-xxl: 400 28px/28px 'Gotham' !default;
$emu-semantic-typography-narrow-headings-xl: 400 24px/26.4px 'Gotham' !default;
$emu-semantic-typography-narrow-headings-large: 400 16px/28.8px 'Gotham' !default;
$emu-semantic-typography-narrow-headings-medium: 700 16px/28.8px 'Gotham' !default;
$emu-semantic-typography-narrow-body-regular: 400 16px/28.8px 'Gotham' !default;
$emu-semantic-typography-wide-headings-xxxl: 400 64px/80px 'Gotham' !default;
$emu-semantic-typography-wide-headings-xxl: 400 41.6px/41.6px 'Gotham' !default;
$emu-semantic-typography-wide-headings-xl: 400 33.6px/37px 'Gotham' !default;
$emu-semantic-typography-wide-headings-large: 400 24px/22.4px 'Gotham' !default;
$emu-semantic-typography-wide-headings-medium: 700 22.4px/28.8px 'Gotham' !default;
$emu-semantic-typography-wide-body-regular: 400 22.4px/28.8px 'Gotham' !default;

$semantic: (
  'semantic': (
    'colors': (
      'none': $emu-semantic-colors-none,
      'surface': (
        'light': $emu-semantic-colors-surface-light,
        'dark': $emu-semantic-colors-surface-dark,
        'modal-backdrop': $emu-semantic-colors-surface-modal-backdrop
      ),
      'actions': (
        'primary': (
          'light': $emu-semantic-colors-actions-primary-light,
          'dark': $emu-semantic-colors-actions-primary-dark
        ),
        'on-primary': (
          'light': $emu-semantic-colors-actions-on-primary-light,
          'dark': $emu-semantic-colors-actions-on-primary-dark
        )
      ),
      'text': (
        'light': $emu-semantic-colors-text-light,
        'dark': $emu-semantic-colors-text-dark
      ),
      'foreground': (
        'light': $emu-semantic-colors-foreground-light,
        'dark': $emu-semantic-colors-foreground-dark
      ),
      'primary': (
        'blue': (
          '100': $emu-semantic-colors-primary-blue-100,
          '200': $emu-semantic-colors-primary-blue-200,
          '300': $emu-semantic-colors-primary-blue-300,
          '400': $emu-semantic-colors-primary-blue-400
        ),
        'green': (
          '100': $emu-semantic-colors-primary-green-100,
          '200': $emu-semantic-colors-primary-green-200
        )
      ),
      'secondary': (
        'grey': (
          '100': $emu-semantic-colors-secondary-grey-100,
          '200': $emu-semantic-colors-secondary-grey-200,
          '300': $emu-semantic-colors-secondary-grey-300,
          '400': $emu-semantic-colors-secondary-grey-400,
          '500': $emu-semantic-colors-secondary-grey-500,
          '600': $emu-semantic-colors-secondary-grey-600,
          '700': $emu-semantic-colors-secondary-grey-700,
          '800': $emu-semantic-colors-secondary-grey-800,
          '900': $emu-semantic-colors-secondary-grey-900,
          '1000': $emu-semantic-colors-secondary-grey-1000,
          '1100': $emu-semantic-colors-secondary-grey-1100,
          '1200': $emu-semantic-colors-secondary-grey-1200
        )
      )
    ),
    'fontWeight': (
      'bold': $emu-semantic-font-weight-bold,
      'light': $emu-semantic-font-weight-light,
      'black': $emu-semantic-font-weight-black,
      'regular': $emu-semantic-font-weight-regular,
      'extra': (
        '500': $emu-semantic-font-weight-extra-500,
        '600': $emu-semantic-font-weight-extra-600
      )
    ),
    'fontFamilies': (
      'body': $emu-semantic-font-families-body,
      'heading': $emu-semantic-font-families-heading,
      'mono': $emu-semantic-font-families-mono
    ),
    'spacing': (
      'none': $emu-semantic-spacing-none,
      'horizontal': $emu-semantic-spacing-horizontal,
      'vertical': $emu-semantic-spacing-vertical,
      'oneLineHeight': (
        'wide': $emu-semantic-spacing-one-line-height-wide,
        'narrow': $emu-semantic-spacing-one-line-height-narrow
      )
    ),
    'fontSizes': (
      'narrow': (
        'medium': $emu-semantic-font-sizes-narrow-medium,
        'large': $emu-semantic-font-sizes-narrow-large,
        'xl': $emu-semantic-font-sizes-narrow-xl,
        'xxl': $emu-semantic-font-sizes-narrow-xxl,
        'xxxl': $emu-semantic-font-sizes-narrow-xxxl
      ),
      'wide': (
        'medium': $emu-semantic-font-sizes-wide-medium,
        'large': $emu-semantic-font-sizes-wide-large,
        'xl': $emu-semantic-font-sizes-wide-xl,
        'xxl': $emu-semantic-font-sizes-wide-xxl,
        'xxxl': $emu-semantic-font-sizes-wide-xxxl
      )
    ),
    'lineHeights': (
      'narrow': (
        'large': $emu-semantic-line-heights-narrow-large,
        'medium': $emu-semantic-line-heights-narrow-medium,
        'xl': $emu-semantic-line-heights-narrow-xl,
        'xxl': $emu-semantic-line-heights-narrow-xxl,
        'xxxl': $emu-semantic-line-heights-narrow-xxxl
      ),
      'wide': (
        'large': $emu-semantic-line-heights-wide-large,
        'medium': $emu-semantic-line-heights-wide-medium,
        'xl': $emu-semantic-line-heights-wide-xl,
        'xxl': $emu-semantic-line-heights-wide-xxl,
        'xxxl': $emu-semantic-line-heights-wide-xxxl
      )
    ),
    'borderRadius': (
      'xs': $emu-semantic-border-radius-xs,
      'small': $emu-semantic-border-radius-small,
      'medium': $emu-semantic-border-radius-medium,
      'large': $emu-semantic-border-radius-large,
      'none': $emu-semantic-border-radius-none
    ),
    'borderWidth': (
      'thin': $emu-semantic-border-width-thin,
      'medium': $emu-semantic-border-width-medium,
      'thick': $emu-semantic-border-width-thick,
      'none': $emu-semantic-border-width-none
    ),
    'outlineWidth': (
      'thin': $emu-semantic-outline-width-thin,
      'medium': $emu-semantic-outline-width-medium,
      'thick': $emu-semantic-outline-width-thick,
      'unset': $emu-semantic-outline-width-unset
    ),
    'sizing': (
      'none': $emu-semantic-sizing-none,
      'xxs': $emu-semantic-sizing-xxs,
      'xs': $emu-semantic-sizing-xs,
      'small': $emu-semantic-sizing-small,
      'medium': $emu-semantic-sizing-medium,
      'large': $emu-semantic-sizing-large,
      'xl': $emu-semantic-sizing-xl,
      'xxl': $emu-semantic-sizing-xxl,
      'breakpoints': (
        'small': $emu-semantic-sizing-breakpoints-small,
        'medium': $emu-semantic-sizing-breakpoints-medium,
        'tablet': $emu-semantic-sizing-breakpoints-tablet,
        'large': $emu-semantic-sizing-breakpoints-large,
        'x-large': $emu-semantic-sizing-breakpoints-x-large,
        'xx-large': $emu-semantic-sizing-breakpoints-xx-large
      ),
      'oneLineHeight': (
        'wide': $emu-semantic-sizing-one-line-height-wide,
        'narrow': $emu-semantic-sizing-one-line-height-narrow
      )
    ),
    'typography': (
      'narrow': (
        'headings': (
          'xxxl': $emu-semantic-typography-narrow-headings-xxxl,
          'xxl': $emu-semantic-typography-narrow-headings-xxl,
          'xl': $emu-semantic-typography-narrow-headings-xl,
          'large': $emu-semantic-typography-narrow-headings-large,
          'medium': $emu-semantic-typography-narrow-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-narrow-body-regular
        )
      ),
      'wide': (
        'headings': (
          'xxxl': $emu-semantic-typography-wide-headings-xxxl,
          'xxl': $emu-semantic-typography-wide-headings-xxl,
          'xl': $emu-semantic-typography-wide-headings-xl,
          'large': $emu-semantic-typography-wide-headings-large,
          'medium': $emu-semantic-typography-wide-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-wide-body-regular
        )
      )
    )
  )
);
