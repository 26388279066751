#home-page {
  .home-page-banner {
    margin-top: -65px;
    position: relative;

    @include mq('small') {
      margin-top: -60px;
    }

    @include mq('medium') {
      margin-top: -75px;
    }

    &__bg {
      min-height: 548px; // value from live
      background-position: 0 100%;
      background-size: 767px !important; // value from live. Important to override the background size property added via inline css in sightly HTML

      @include mq('small') {
        min-height: 1020px; // value from live
        background-position: 50% 100%;
        background-size: initial !important; // Important to override the previous default property
      }

      @include mq('medium') {
        min-height: 942px; // value from live
      }

      @include mq('tablet') {
        min-height: 1020px; // value from live
        position: relative;
        z-index: var(--emu-common-other-z-index-layer);
        background-color: transparent;
      }
    }

    &__content {
      margin-top: var(--emu-common-spacing-none);
      min-height: 400px; // value from live site
      background-color: var(--emu-common-colors-transparent);
      position: relative;
      z-index: var(--emu-common-other-z-index-layer);
      overflow: hidden;

      @include mq('small') {
        margin-top: -109px;
      }

      @include mq('medium') {
        position: absolute;
        top: 460px;
        right: 0;
      }

      @include mq('tablet') {
        top: 390px;
        z-index: var(--emu-common-other-z-index-layer);
      }

      h1 {
        font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
        line-height: var(--emu-semantic-line-heights-narrow-xxxl);
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-large);
        width: 320px; // value from live
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-wide-xxxl);
          line-height: var(--emu-semantic-line-heights-wide-xxxl);
          text-align: left;
          margin-right: var(--emu-common-spacing-none);
        }

        @include mq('tablet') {
          margin-bottom: 120px;
          font-size: 94px;
          line-height: 110px;
          width: 384px;
          margin-right: 35px;

          .emphasis {
            margin-left: -53px;
            display: block;
          }
        }

        @include mq('large') {
          font-size: 110px;
          width: 496px;
          margin-bottom: 124px;
        }
      }

      p {
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        line-height: 22px;
        color: var(--emu-semantic-colors-secondary-grey-400);
        font-weight: var(--emu-semantic-font-weight-extra-500);
        width: 336px; // value from live
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
        max-width: 100%;

        @include mq('medium') {
          text-align: left;
          padding-right: 40px;
          width: 376px; // value deduced from live site
        }

        @include mq('tablet') {
          font-size: 32px;
          line-height: 38px;
          padding-right: var(--emu-common-spacing-none);
          width: 656px;
          margin-right: 11px;
        }

        @include mq('large') {
          width: 752px;
        }
      }

      sup {
        font-weight: var(--emu-common-font-weight-regular);
        font-size: 11px;

        @include mq('tablet') {
          font-size: 16px;
          top: -14px;
          left: -4px;
        }
      }
    }

    &__logo {
      img {
        height: 75px; // value from live
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: var(--emu-common-spacing-large);

        @include mq('medium') {
          margin-right: 40px;
          margin-bottom: 20px;
        }

        @include mq('tablet') {
          height: 95px; // value from live site
          margin-bottom: 75px;
          margin-right: 65px;
        }

        @include mq('large') {
          height: 127px; // value from live site
          margin-bottom: 40px;
        }
      }
    }

    &__bottom-asset {
      height: 190px; // value deduced from live
      background-position: 50% 0;
      margin-top: -185px; // value deduced from live
      background-color: var(--emu-common-colors-transparent);
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 6px;
        background-color: var(--emu-semantic-colors-secondary-grey-100);
      }

      @include mq('small') {
        margin-top: -138px;
      }

      @include mq('medium') {
        margin-top: -258px;
      }

      @include mq('tablet') {
        position: relative;
        z-index: var(--emu-common-other-z-index-base);
        margin-top: -248px;
      }

      @include mq('large') {
        margin-top: -182px;
      }
    }
  }

  .simple-steps {
    position: relative;
    background-color: rgba(235, 235, 235, 0.5); // no variable, used only once
    padding-top: 15px;

    @include mq('small') {
      padding-top: 57px;
    }

    @include mq('large') {
      padding-top: 15px;
    }

    .aaaem-container {
      background-color: var(--emu-common-colors-transparent);
    }

    &-title {
      h2 {
        color: var(--emu-semantic-colors-primary-green-100);
        letter-spacing: 0;
        margin: var(--emu-common-spacing-none);
        font-weight: var(--emu-semantic-font-weight-extra-500);

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-wide-xxl);
          line-height: var(--emu-semantic-line-heights-wide-xxl);
        }

        sup,
        .emphasis {
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          position: relative;
          line-height: 0;
          top: -16px;
          font-weight: var(--emu-common-font-weight-regular);

          @include mq('medium') {
            top: -21px;
          }
        }

        .emphasis {
          font-size: inherit;
          line-height: inherit;
          top: -8px;
          font-weight: inherit;

          @include mq('medium') {
            top: -4px;
          }
        }
      }
    }

    &-container {
      padding-top: var(--emu-common-spacing-large);

      @include mq('medium') {
        display: flex;
        flex-wrap: wrap;
        max-width: 95%;
      }

      @include mq('tablet') {
        max-width: 1000px; // needed to match design
        width: 95%;
      }

      > div.container {
        @include mq('medium') {
          width: 50%;
        }

        @include mq('tablet') {
          width: 25%;
        }
      }

      &--inner {
        background-color: inherit;

        img {
          margin-left: auto;
          margin-right: auto;
          display: block;
        }

        &-text {
          h2 {
            font-size: var(--emu-semantic-font-sizes-narrow-medium);
            line-height: var(--emu-semantic-line-heights-narrow-xxl);
            font-weight: var(--emu-semantic-font-weight-extra-500);
            margin-bottom: 24px;
            margin-top: 24px;
            color: var(--emu-semantic-colors-primary-green-100);

            @include mq('medium') {
              font-size: var(--emu-semantic-font-sizes-wide-large);
              line-height: var(--emu-semantic-line-heights-wide-medium);
            }
          }
        }
      }
    }

    &-footnote {
      padding-top: 34px;
      padding-bottom: var(--emu-common-spacing-large);
      max-width: 320px; // value from live-site

      @include mq('medium') {
        max-width: 100%;
      }

      @include mq('tablet') {
        padding-top: 42px;
      }

      p {
        color: var(--emu-semantic-colors-secondary-grey-500);
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
        line-height: 22px;
      }
    }
  }

  .instruction-section {
    background-color: var(--emu-semantic-colors-secondary-grey-1200);

    @include mq('medium') {
      display: flex;
    }

    > div {
      @include mq('medium') {
        width: 50%;
        height: auto;
      }
    }

    &__banner {
      margin-bottom: var(--emu-common-spacing-none);
      background-color: var(--emu-common-colors-transparent);

      @include mq('medium') {
        height: 100%;
      }

      .image {
        @include mq('medium') {
          height: 100%;
        }
      }

      .video-card {
        &__play-text {
          background-position: center bottom;
          background-size: 20%;

          @include mq('medium') {
            background-size: 115px;
          }

          .aaaem-text {
            position: static;
            transform: none;
            margin-top: var(--emu-common-spacing-none);
          }
        }

        &__bg {
          @include mq('large') {
            height: auto;
          }
        }
      }
    }

    &__contact {
      padding-top: var(--emu-common-spacing-xl);
      padding-bottom: 29px;
      padding-left: var(--emu-common-spacing-medium);
      padding-right: var(--emu-common-spacing-medium);
      background-color: var(--emu-common-colors-transparent);
      min-height: 285px;

      @include mq('medium') {
        padding-top: var(--emu-common-spacing-medium);
        padding-bottom: var(--emu-common-spacing-large);
      }

      @include mq('tablet') {
        padding-top: var(--emu-common-border-radius-large);
      }

      @include mq('large') {
        padding-top: 80px;
      }

      &-image {
        img {
          width: 80px; // needed to match design. Value from live site
          margin-left: auto;
          margin-right: auto;

          @include mq('medium') {
            width: 30%;
          }

          @include mq('tablet') {
            width: auto;
          }
        }
      }

      &-text {
        width: 72%;
        padding-top: var(--emu-common-spacing-large);

        @include mq('small') {
          width: 42%;
        }

        @include mq('medium') {
          padding-top: 48px;
          width: 69%;
        }

        @include mq('tablet') {
          width: 86%;
        }

        @include mq('large') {
          width: 68%;
        }

        h2 {
          color: var(--emu-semantic-colors-secondary-grey-300);
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          font-weight: var(--emu-semantic-font-weight-extra-500);
          line-height: var(--emu-semantic-line-heights-narrow-large);
          margin: var(--emu-common-spacing-none);

          @include mq('medium') {
            line-height: 24px;
          }

          @include mq('tablet') {
            font-size: var(--emu-semantic-font-sizes-narrow-xxl);
            line-height: var(--emu-semantic-line-heights-narrow-xxl);
          }
        }
      }
    }
  }

  .payment-record {
    padding-top: 60px;
    padding-bottom: var(--emu-common-spacing-large);

    &-title,
    &-sub-title {
      padding-left: 20px;
      padding-right: 20px;

      @include mq('medium') {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      h3,
      h4 {
        font-weight: var(--emu-semantic-font-weight-extra-500);
        margin: var(--emu-common-spacing-none);
      }
    }

    &-title {
      h3 {
        font-size: 26px;
        line-height: var(--emu-semantic-line-heights-narrow-xl);
        color: var(--emu-semantic-colors-primary-green-100);
        word-break: break-all;

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-wide-xxl);
          line-height: 1;
          padding-top: 20px;
        }

        // needed breakpoint. We have 1025, but not 1024
        @include mq('1024px') {
          font-size: 38.4px;
        }

        // needed breakpoint to match the design
        @include mq('1367px') {
          font-size: var(--emu-semantic-font-sizes-wide-xxl);
        }

        sup {
          font-size: 18px;

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-narrow-xxl);
            top: -10px;
          }

          u {
            text-decoration: none;
            font-family: initial;
            font-size: 18px;

            @include mq('medium') {
              font-size: 29px;
            }
          }
        }
      }
    }

    &-sub-title {
      margin-top: 30px;

      h4 {
        font-size: 18px;
        line-height: 25px;
        color: var(--emu-semantic-colors-secondary-grey-300);

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-narrow-xxl);
          line-height: var(--emu-semantic-line-heights-wide-xxl);
        }
      }
    }

    &-image {
      max-width: 95%;
      padding-top: var(--emu-common-spacing-large);

      @include mq('medium') {
        display: flex;
        justify-content: space-around;
      }

      @include mq('tablet') {
        width: 1000px;
        justify-content: center;
        gap: calc(25% - 60px);

        &::before,
        &::after {
          content: '';
          display: block;
        }
      }

      > .image {
        margin-left: 10px;

        img {
          margin-left: auto;
          margin-right: auto;
        }

        @include mq('medium') {
          margin-left: var(--emu-common-spacing-none);
        }

        @include mq('tablet') {
          min-width: 235px;
          position: relative;
          &:first-child {
            left: 25px;
          }

          &:last-child {
            left: 5px;
          }
        }

        // needed breakpoint. We have 1025, but not 1024
        @include mq('1024px') {
          &:first-child {
            left: 10px;
          }
        }
      }

      &-left,
      &-right {
        margin-bottom: 47px;
      }
    }

    &-features-link-text {
      max-width: 566px; // needed to match design. Value from live site
      width: 85%;
      display: block;
      margin-bottom: 60px;
      margin-top: 53px;

      @include mq('medium') {
        margin-top: 10px;
      }

      @include mq('tablet') {
        margin-bottom: 60px;
      }

      h2 {
        margin: var(--emu-common-spacing-none);

        a {
          border-radius: 3px;
          background-color: var(--emu-semantic-colors-primary-green-100);
          width: 100%;
          display: block;
          text-decoration: none;
          padding-top: 15px;
          padding-bottom: 15px;
          padding-left: 25px;
          padding-right: 25px;
          font-size: var(--emu-semantic-font-sizes-narrow-xl);
          line-height: 30px;
          color: var(--emu-common-colors-white);
          font-weight: var(--emu-semantic-font-weight-extra-500);
        }
      }
    }

    &-references {
      max-width: 320px; // needed to match design. Value from live site

      @include mq('medium') {
        max-width: 700px; // needed to match design. Value from live site
      }

      @include mq('tablet') {
        max-width: 100%;
      }

      p {
        margin: var(--emu-common-spacing-none);
        line-height: var(--emu-semantic-line-heights-wide-large);
        color: var(--emu-semantic-colors-secondary-grey-500);

        sup {
          u {
            font-family: initial;
            text-decoration: none;
          }
        }
      }
    }
  }
}
