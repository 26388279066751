.video-card {
  position: relative;
  margin-bottom: 5px;

  @include mq('medium') {
    margin-bottom: var(--emu-common-spacing-none);
  }

  &__play-text {
    background-image: url('./assets/images/play-button.png');
    background-size: min(20%, 115px);
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--emu-common-colors-transparent);
    width: 100%;
    transform: translate(-50%, -50%);
    padding-bottom: min(20%, 115px);

    @include mq('medium') {
      padding-bottom: 115px;
      background-size: 115px;
    }

    .aaaem-text {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
      font-size: 30px;
      line-height: 30px;
      color: var(--emu-semantic-colors-secondary-grey-300);
      z-index: var(--emu-common-other-z-index-layer);

      p {
        font-weight: var(--emu-semantic-font-weight-extra-500);
        text-align: center;

        @include mq('tablet') {
          white-space: nowrap;
        }
      }
    }
  }

  &__bg {
    min-height: 220px; // needed to match design
    display: flex;

    img {
      height: auto;
    }
  }

  .button {
    position: static;
  }

  // additional class to increase specificity and override
  &__trigger.cmp-button {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: var(--emu-common-other-z-index-layer);
    background-color: var(--emu-semantic-colors-none);
    border: var(--emu-semantic-colors-none);

    &:hover,
    &:focus,
    &:active,
    &:visited {
      background-color: var(--emu-semantic-colors-none);
      border: var(--emu-semantic-colors-none);
    }

    @include mq('tablet') {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
    }
  }
}
