@font-face {
  font-family: Gotham;
  src: url("resources/fonts/Gotham-Black.woff") format("woff");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Gotham;
  src: url("resources/fonts/Gotham-BlackItalic.woff") format("woff");
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Gotham;
  src: url("resources/fonts/Gotham-Bold.woff") format("woff");
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Gotham;
  src: url("resources/fonts/Gotham-BoldItalic.woff") format("woff");
  font-display: swap;
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Gotham;
  src: url("resources/fonts/Gotham-Book.woff") format("woff");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Gotham;
  src: url("resources/fonts/Gotham-BookItalic.woff") format("woff");
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Gotham;
  src: url("resources/fonts/Gotham-Light.woff") format("woff");
  font-display: swap;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Gotham;
  src: url("resources/fonts/Gotham-LightItalic.woff") format("woff");
  font-display: swap;
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Gotham;
  src: url("resources/fonts/Gotham-Medium.woff") format("woff");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Gotham;
  src: url("resources/fonts/Gotham-MediumItalic.woff") format("woff");
  font-display: swap;
  font-weight: 500;
  font-style: italic;
}

body {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-regular);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-bold);
}

h1 {
  font: var(--emu-component-ingredients-title-h1-typography-narrow);
}

h2 {
  font: var(--emu-component-ingredients-title-h2-typography-narrow);
}

h3 {
  font: var(--emu-component-ingredients-title-h3-typograph-narrow);
}

h4 {
  font: var(--emu-component-ingredients-title-h4-typography-narrow);
}

h5 {
  font: var(--emu-component-ingredients-title-h5-typography-narrow);
}

:root {
  --emu-common-spacing-xs: 4px;
  --emu-common-spacing-xxs: 2px;
  --emu-common-spacing-small: 8px;
  --emu-common-spacing-medium: 16px;
  --emu-common-spacing-large: 32px;
  --emu-common-spacing-xl: 64px;
  --emu-common-spacing-none: 0px;
  --emu-common-spacing-unset: unset;
  --emu-common-colors-white: #fff;
  --emu-common-colors-black: #000;
  --emu-common-colors-red-100: #ff7570;
  --emu-common-colors-red-200: #ff615c;
  --emu-common-colors-red-300: #ff4e47;
  --emu-common-colors-red-400: #ff3a33;
  --emu-common-colors-red-500: #ff1d15;
  --emu-common-colors-red-600: #ff120a;
  --emu-common-colors-red-700: #f50800;
  --emu-common-colors-red-800: #e00700;
  --emu-common-colors-red-900: #cc0700;
  --emu-common-colors-grey-100: #f4f5f5;
  --emu-common-colors-grey-200: #d4d7d8;
  --emu-common-colors-grey-300: #b4b8bb;
  --emu-common-colors-grey-400: #949a9e;
  --emu-common-colors-grey-500: #7e868b;
  --emu-common-colors-grey-600: #61676b;
  --emu-common-colors-grey-700: #44484b;
  --emu-common-colors-grey-800: #303436;
  --emu-common-colors-grey-900: #1d1f20;
  --emu-common-colors-transparent: #0000;
  --emu-common-font-families-sans: Arial;
  --emu-common-font-families-serif: Times New Roman;
  --emu-common-font-families-mono: Courier;
  --emu-common-font-weight-bold: 700;
  --emu-common-font-weight-light: 300;
  --emu-common-font-weight-black: 900;
  --emu-common-font-weight-regular: 400;
  --emu-common-font-sizes-narrow-medium: 12px;
  --emu-common-font-sizes-narrow-large: 16.97px;
  --emu-common-font-sizes-narrow-xl: 23.99px;
  --emu-common-font-sizes-narrow-xxl: 33.93px;
  --emu-common-font-sizes-narrow-xxxl: 47.97px;
  --emu-common-font-sizes-wide-medium: 16px;
  --emu-common-font-sizes-wide-large: 22.62px;
  --emu-common-font-sizes-wide-xl: 31.99px;
  --emu-common-font-sizes-wide-xxl: 45.23px;
  --emu-common-font-sizes-wide-xxxl: 63.96px;
  --emu-common-line-heights-narrow-large: 18px;
  --emu-common-line-heights-narrow-medium: 18px;
  --emu-common-line-heights-narrow-xl: 36px;
  --emu-common-line-heights-narrow-xxl: 36px;
  --emu-common-line-heights-narrow-xxxl: 54px;
  --emu-common-line-heights-wide-large: 24px;
  --emu-common-line-heights-wide-medium: 24px;
  --emu-common-line-heights-wide-xl: 48px;
  --emu-common-line-heights-wide-xxl: 48px;
  --emu-common-line-heights-wide-xxxl: 72px;
  --emu-common-border-radius-xxs: 2px;
  --emu-common-border-radius-xs: 4px;
  --emu-common-border-radius-small: 8px;
  --emu-common-border-radius-medium: 16px;
  --emu-common-border-radius-large: 32px;
  --emu-common-border-radius-none: 0px;
  --emu-common-border-width-thin: 1px;
  --emu-common-border-width-medium: 2px;
  --emu-common-border-width-thick: 4px;
  --emu-common-border-width-none: 0px;
  --emu-common-outline-width-thin: thin;
  --emu-common-outline-width-medium: medium;
  --emu-common-outline-width-thick: thick;
  --emu-common-outline-width-unset: unset;
  --emu-common-sizing-none: 0px;
  --emu-common-sizing-xxs: 1px;
  --emu-common-sizing-xs: 8px;
  --emu-common-sizing-small: 16px;
  --emu-common-sizing-medium: 32px;
  --emu-common-sizing-large: 64px;
  --emu-common-sizing-xl: 128px;
  --emu-common-sizing-xxl: 256px;
  --emu-common-sizing-container: 1520px;
  --emu-common-other-time-transition-short: 200ms;
  --emu-common-other-time-transition-base: 400ms;
  --emu-common-other-time-transition-long: 600ms;
  --emu-common-other-time-transition-xl: 1000ms;
  --emu-common-other-time-duration-instant: 400ms;
  --emu-common-other-time-duration-short: 2000ms;
  --emu-common-other-time-duration-base: 4000ms;
  --emu-common-other-time-duration-long: 6000ms;
  --emu-common-other-time-delay-none: 0ms;
  --emu-common-other-time-delay-short: 50ms;
  --emu-common-other-time-delay-base: 100ms;
  --emu-common-other-time-delay-long: 200ms;
  --emu-common-other-z-index-modal: 600;
  --emu-common-other-z-index-header: 500;
  --emu-common-other-z-index-isi: 400;
  --emu-common-other-z-index-overlay: 300;
  --emu-common-other-z-index-layer: 100;
  --emu-common-other-z-index-base: 0;
  --emu-common-other-z-index-behind: -1;
  --emu-semantic-colors-none: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-light: var(--emu-common-colors-white);
  --emu-semantic-colors-surface-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-surface-modal-backdrop: #00000080;
  --emu-semantic-colors-actions-primary-light: var(--emu-common-colors-transparent);
  --emu-semantic-colors-actions-primary-dark: var(--emu-common-colors-transparent);
  --emu-semantic-colors-actions-on-primary-light: var(--emu-semantic-colors-primary-green-100);
  --emu-semantic-colors-actions-on-primary-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-text-light: var(--emu-semantic-colors-secondary-grey-500);
  --emu-semantic-colors-text-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-foreground-light: var(--emu-common-colors-white);
  --emu-semantic-colors-foreground-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-primary-blue-100: #1f9bde;
  --emu-semantic-colors-primary-blue-200: #001450;
  --emu-semantic-colors-primary-blue-300: #19a5e1;
  --emu-semantic-colors-primary-blue-400: #23527c;
  --emu-semantic-colors-primary-green-100: #00b0c6;
  --emu-semantic-colors-primary-green-200: #00adbb;
  --emu-semantic-colors-secondary-grey-100: #f0f0f0;
  --emu-semantic-colors-secondary-grey-200: #686868;
  --emu-semantic-colors-secondary-grey-300: #63656a;
  --emu-semantic-colors-secondary-grey-400: #6f6f73;
  --emu-semantic-colors-secondary-grey-500: #54565b;
  --emu-semantic-colors-secondary-grey-600: #464646;
  --emu-semantic-colors-secondary-grey-700: #333;
  --emu-semantic-colors-secondary-grey-800: #303030;
  --emu-semantic-colors-secondary-grey-900: #65646a;
  --emu-semantic-colors-secondary-grey-1000: #adadad;
  --emu-semantic-colors-secondary-grey-1100: #ddd;
  --emu-semantic-colors-secondary-grey-1200: #f5f5f5;
  --emu-semantic-font-weight-bold: var(--emu-common-font-weight-bold);
  --emu-semantic-font-weight-light: var(--emu-common-font-weight-light);
  --emu-semantic-font-weight-black: var(--emu-common-font-weight-black);
  --emu-semantic-font-weight-regular: var(--emu-common-font-weight-regular);
  --emu-semantic-font-weight-extra-500: 500;
  --emu-semantic-font-weight-extra-600: 600;
  --emu-semantic-font-families-body: "Gotham";
  --emu-semantic-font-families-heading: "Gotham";
  --emu-semantic-font-families-mono: "Gotham";
  --emu-semantic-spacing-none: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal: var(--emu-common-spacing-none);
  --emu-semantic-spacing-vertical: var(--emu-common-spacing-none);
  --emu-semantic-spacing-one-line-height-wide: var(--emu-common-spacing-none);
  --emu-semantic-spacing-one-line-height-narrow: var(--emu-common-spacing-none);
  --emu-semantic-font-sizes-narrow-medium: 16px;
  --emu-semantic-font-sizes-narrow-large: 16px;
  --emu-semantic-font-sizes-narrow-xl: 24px;
  --emu-semantic-font-sizes-narrow-xxl: 28px;
  --emu-semantic-font-sizes-narrow-xxxl: 62px;
  --emu-semantic-font-sizes-wide-medium: 22.4px;
  --emu-semantic-font-sizes-wide-large: 24px;
  --emu-semantic-font-sizes-wide-xl: 33.6px;
  --emu-semantic-font-sizes-wide-xxl: 41.6px;
  --emu-semantic-font-sizes-wide-xxxl: 64px;
  --emu-semantic-line-heights-narrow-large: 16px;
  --emu-semantic-line-heights-narrow-medium: 28.8px;
  --emu-semantic-line-heights-narrow-xl: 26.4px;
  --emu-semantic-line-heights-narrow-xxl: 28px;
  --emu-semantic-line-heights-narrow-xxxl: 64px;
  --emu-semantic-line-heights-wide-large: 22.4px;
  --emu-semantic-line-heights-wide-medium: 28.8px;
  --emu-semantic-line-heights-wide-xl: 37px;
  --emu-semantic-line-heights-wide-xxl: 41.6px;
  --emu-semantic-line-heights-wide-xxxl: 80px;
  --emu-semantic-border-radius-xs: var(--emu-common-border-radius-xs);
  --emu-semantic-border-radius-small: var(--emu-common-border-radius-small);
  --emu-semantic-border-radius-medium: var(--emu-common-border-radius-medium);
  --emu-semantic-border-radius-large: var(--emu-common-border-radius-large);
  --emu-semantic-border-radius-none: var(--emu-common-border-radius-none);
  --emu-semantic-border-width-thin: var(--emu-common-border-width-thin);
  --emu-semantic-border-width-medium: var(--emu-common-border-width-medium);
  --emu-semantic-border-width-thick: var(--emu-common-border-width-thick);
  --emu-semantic-border-width-none: 0px;
  --emu-semantic-outline-width-thin: var(--emu-common-outline-width-thin);
  --emu-semantic-outline-width-medium: var(--emu-common-outline-width-medium);
  --emu-semantic-outline-width-thick: var(--emu-common-outline-width-thick);
  --emu-semantic-outline-width-unset: unset;
  --emu-semantic-sizing-none: var(--emu-common-sizing-none);
  --emu-semantic-sizing-xxs: var(--emu-common-sizing-xxs);
  --emu-semantic-sizing-xs: var(--emu-common-sizing-xs);
  --emu-semantic-sizing-small: var(--emu-common-sizing-small);
  --emu-semantic-sizing-medium: var(--emu-common-sizing-medium);
  --emu-semantic-sizing-large: var(--emu-common-sizing-large);
  --emu-semantic-sizing-xl: var(--emu-common-sizing-xl);
  --emu-semantic-sizing-xxl: var(--emu-common-sizing-xxl);
  --emu-semantic-sizing-breakpoints-small: 480px;
  --emu-semantic-sizing-breakpoints-medium: 768px;
  --emu-semantic-sizing-breakpoints-tablet: 992px;
  --emu-semantic-sizing-breakpoints-large: 1025px;
  --emu-semantic-sizing-breakpoints-x-large: 1280px;
  --emu-semantic-sizing-breakpoints-xx-large: 1440px;
  --emu-semantic-sizing-one-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-semantic-sizing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-semantic-typography-narrow-headings-xxxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xxxl) / var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-line-heights-narrow-xxl) / var(--emu-semantic-font-sizes-narrow-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xl) / var(--emu-semantic-line-heights-narrow-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xxxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xxxl) / var(--emu-semantic-line-heights-wide-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-line-heights-wide-xxl) / var(--emu-semantic-font-sizes-wide-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xl) / var(--emu-semantic-line-heights-wide-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-large) / var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-wide-medium) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-medium) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-body);
  --emu-component-cards-card-margin-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-cards-card-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-button-text-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-size-height: 30px;
  --emu-component-cards-card-header-button-icon-size-width: 30px;
  --emu-component-cards-card-header-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-cards-card-header-button-icon-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-cards-card-header-button-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-teaser-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-cards-teaser-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-cards-teaser-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-cards-teaser-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-cards-teaser-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-cards-teaser-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-cards-teaser-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-cards-teaser-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-cards-teaser-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-title-max-width: 100%;
  --emu-component-cards-teaser-title-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-top-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-lists-accordion-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-color-text-light: var(--emu-semantic-colors-secondary-grey-500);
  --emu-component-lists-accordion-color-text-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-accordion-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-margin-top-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-top-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-right-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-right-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-bottom-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-bottom-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-left-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-left-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-color-border-bottom-closed-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-color-border-bottom-open-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-color-border-bottom-open-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-body-padding-top-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-body-padding-top-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-body-padding-right-narrow: 24px;
  --emu-component-lists-accordion-item-body-padding-right-wide: 24px;
  --emu-component-lists-accordion-item-body-padding-bottom-narrow: 13px;
  --emu-component-lists-accordion-item-body-padding-bottom-wide: 13px;
  --emu-component-lists-accordion-item-body-padding-left-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-body-padding-left-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-lists-accordion-item-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-lists-accordion-item-header-padding-top-narrow: 32px;
  --emu-component-lists-accordion-item-header-padding-top-wide: 32px;
  --emu-component-lists-accordion-item-header-padding-right-narrow: 24px;
  --emu-component-lists-accordion-item-header-padding-right-wide: 24px;
  --emu-component-lists-accordion-item-header-padding-bottom-narrow: 36px;
  --emu-component-lists-accordion-item-header-padding-bottom-wide: 36px;
  --emu-component-lists-accordion-item-header-padding-left-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-padding-left-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-spacing: var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-header-icon-size-height: 15px;
  --emu-component-lists-accordion-item-header-icon-size-width: 15px;
  --emu-component-lists-accordion-item-header-icon-thickness: var(--emu-semantic-border-width-thick);
  --emu-component-lists-accordion-item-header-icon-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-item-header-icon-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-item-header-icon-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-item-header-icon-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-item-header-icon-transform-open: rotate(0);
  --emu-component-lists-accordion-item-header-icon-transform-closed: rotate(180deg);
  --emu-component-lists-accordion-item-header-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-light: var(--emu-semantic-colors-primary-blue-100);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-light: var(--emu-semantic-colors-primary-blue-100);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-lists-accordion-item-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-color-background-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-background-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-color-background-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-border-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-border-hover-light: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-text-color-default-light: var(--emu-semantic-colors-secondary-grey-300);
  --emu-component-lists-navigation-banner-item-text-color-default-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-text-color-hover-light: var(--emu-semantic-colors-secondary-grey-300);
  --emu-component-lists-navigation-banner-item-text-color-hover-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-border-width: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-banner-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-dropdown-color-background-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-color-background-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-thin);
  --emu-component-lists-navigation-dropdown-item-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-item-color-background-default-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-dark: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-z-index: 500;
  --emu-component-lists-navigation-text-padding-top-narrow: 15px;
  --emu-component-lists-navigation-text-padding-top-wide: 30px;
  --emu-component-lists-navigation-text-padding-right-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-lists-navigation-text-padding-right-wide: 30px;
  --emu-component-lists-navigation-text-padding-bottom-narrow: 15px;
  --emu-component-lists-navigation-text-padding-bottom-wide: 30px;
  --emu-component-lists-navigation-text-padding-left-narrow: 20px;
  --emu-component-lists-navigation-text-padding-left-wide: 30px;
  --emu-component-feeds-instagram-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-feeds-instagram-padding-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-feeds-instagram-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-post-gap-narrow: 40px;
  --emu-component-feeds-instagram-post-gap-wide: 40px;
  --emu-component-ingredients-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-icon-size-height: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-separator-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-separator-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-separator-size-height: var(--emu-semantic-sizing-xxs);
  --emu-component-ingredients-title-h1-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxxl);
  --emu-component-ingredients-title-h1-typography-wide: var(--emu-semantic-typography-wide-headings-xxxl);
  --emu-component-ingredients-title-h2-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-ingredients-title-h2-typography-wide: var(--emu-semantic-typography-wide-headings-xxl);
  --emu-component-ingredients-title-h3-typography-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-ingredients-title-h3-typography-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-ingredients-title-h4-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-ingredients-title-h4-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-ingredients-title-h5-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-ingredients-title-h5-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-ingredients-title-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-title-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-ingredients-text-text-typography-wide: var(--emu-semantic-typography-wide-body-regular);
  --emu-component-ingredients-loader-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-loader-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-image-map-buttons-size-height: 27px;
  --emu-component-ingredients-image-map-buttons-size-width: 27px;
  --emu-component-ingredients-image-map-buttons-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-image-map-buttons-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-image-map-buttons-color-icon-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-ingredients-image-map-buttons-color-icon-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-ingredients-image-map-buttons-border-radius-top-left: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-top-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-left: 50%;
  --emu-component-ingredients-image-map-buttons-shadow: 0 0 2 5 #0009;
  --emu-component-ingredients-google-map-color-background-light: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-color-background-dark: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-margin-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-right-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-ingredients-google-map-margin-right-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-ingredients-google-map-margin-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-left-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-ingredients-google-map-margin-left-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-text-typography-narrow: var(--emu-semantic-font-weight-bold) 18px / 26px var(--emu-semantic-font-families-heading);
  --emu-component-actions-button-text-typography-wide: var(--emu-semantic-font-weight-bold) 18px / 26px var(--emu-semantic-font-families-heading);
  --emu-component-actions-button-text-color-link-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-dark: var(--emu-semantic-colors-secondary-grey-500);
  --emu-component-actions-button-text-color-link-hover-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-hover-dark: var(--emu-semantic-colors-secondary-grey-500);
  --emu-component-actions-button-text-color-primary-filled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-focus-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-hover-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-active-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-outline-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-dark: var(--emu-semantic-colors-secondary-grey-500);
  --emu-component-actions-button-text-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-focus-dark: var(--emu-semantic-colors-secondary-grey-500);
  --emu-component-actions-button-text-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-hover-dark: var(--emu-semantic-colors-secondary-grey-500);
  --emu-component-actions-button-text-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-active-dark: var(--emu-semantic-colors-secondary-grey-500);
  --emu-component-actions-button-text-color-secondary-filled-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-dark: var(--emu-semantic-colors-secondary-grey-300);
  --emu-component-actions-button-text-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-focus-dark: var(--emu-semantic-colors-secondary-grey-300);
  --emu-component-actions-button-text-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-hover-dark: var(--emu-semantic-colors-secondary-grey-300);
  --emu-component-actions-button-text-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-active-dark: var(--emu-semantic-colors-secondary-grey-300);
  --emu-component-actions-button-padding-top-narrow: 16px;
  --emu-component-actions-button-padding-top-wide: 15px;
  --emu-component-actions-button-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-actions-button-padding-right-wide: 16px;
  --emu-component-actions-button-padding-bottom-narrow: 16px;
  --emu-component-actions-button-padding-bottom-wide: 15px;
  --emu-component-actions-button-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-actions-button-padding-left-wide: 16px;
  --emu-component-actions-button-border-width-primary-filled: var(--emu-common-border-width-medium);
  --emu-component-actions-button-border-width-primary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-outline: var(--emu-common-border-width-none);
  --emu-component-actions-button-outline-width-primary-filled: var(--emu-semantic-outline-width-unset);
  --emu-component-actions-button-outline-width-primary-outline: var(--emu-semantic-outline-width-unset);
  --emu-component-actions-button-outline-width-secondary-filled: var(--emu-semantic-outline-width-unset);
  --emu-component-actions-button-outline-width-secondary-outline: var(--emu-semantic-outline-width-unset);
  --emu-component-actions-button-border-radius-primary-filled-top-left: 3px;
  --emu-component-actions-button-border-radius-primary-filled-top-right: 3px;
  --emu-component-actions-button-border-radius-primary-filled-bottom-right: 3px;
  --emu-component-actions-button-border-radius-primary-filled-bottom-left: 3px;
  --emu-component-actions-button-border-radius-primary-outline-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-color-border-primary-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-filled-dark: var(--emu-semantic-colors-primary-green-100);
  --emu-component-actions-button-color-border-primary-filled-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-filled-focus-dark: var(--emu-semantic-colors-primary-green-100);
  --emu-component-actions-button-color-border-primary-filled-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-filled-hover-dark: var(--emu-semantic-colors-primary-green-100);
  --emu-component-actions-button-color-border-primary-filled-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-filled-active-dark: var(--emu-semantic-colors-primary-green-100);
  --emu-component-actions-button-color-border-primary-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-outline-dark: var(--emu-semantic-colors-secondary-grey-300);
  --emu-component-actions-button-color-border-primary-outline-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-outline-focus-dark: var(--emu-semantic-colors-secondary-grey-300);
  --emu-component-actions-button-color-border-primary-outline-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-hover-dark: var(--emu-semantic-colors-secondary-grey-300);
  --emu-component-actions-button-color-border-primary-outline-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-active-dark: var(--emu-semantic-colors-secondary-grey-300);
  --emu-component-actions-button-color-border-secondary-filled-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-filled-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-filled-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-filled-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-filled-dark: var(--emu-semantic-colors-primary-green-100);
  --emu-component-actions-button-color-background-primary-filled-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-filled-focus-dark: var(--emu-semantic-colors-primary-green-100);
  --emu-component-actions-button-color-background-primary-filled-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-hover-dark: var(--emu-semantic-colors-primary-green-100);
  --emu-component-actions-button-color-background-primary-filled-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-active-dark: var(--emu-semantic-colors-primary-green-100);
  --emu-component-actions-button-color-background-primary-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-focus-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-outline-hover-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-outline-active-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-filled-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-dark: var(--emu-semantic-colors-secondary-grey-100);
  --emu-component-actions-button-color-background-secondary-outline-focus-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-focus-dark: var(--emu-semantic-colors-secondary-grey-100);
  --emu-component-actions-button-color-background-secondary-outline-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-outline-hover-dark: var(--emu-semantic-colors-secondary-grey-100);
  --emu-component-actions-button-color-background-secondary-outline-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-outline-active-dark: var(--emu-semantic-colors-secondary-grey-100);
  --emu-component-actions-button-color-outline-color-primary-filled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-outline-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-group-horizontal-gap: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-left: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-right: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-top: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-horizontal-padding-bottom: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-vertical-gap: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-left: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-right: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-top: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-vertical-padding-bottom: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-icon-size-height: 12px;
  --emu-component-actions-button-icon-size-width: 12px;
  --emu-component-actions-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-actions-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-actions-button-icon-border-radius-top-left: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-top-right: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-bottom-right: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-bottom-left: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-transform-open: rotate(0);
  --emu-component-actions-button-icon-transform-closed: rotate(180deg);
  --emu-component-actions-button-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-actions-button-icon-color-primary-filled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-back-to-top-z-index: 600;
  --emu-component-actions-back-to-top-icon-size-height: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-icon-size-width: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-position-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-left-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-left-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-right-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-right-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-top-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-top-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-margin-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-left-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-left-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-right-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-right-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-scroll-reveal-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-padding-top-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-padding-top-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-static-section-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-cards-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-comparison-slider-divider-color-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-comparison-slider-divider-color-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-comparison-slider-divider-size-width-narrow: 2px;
  --emu-component-containers-comparison-slider-divider-size-width-wide: 2px;
  --emu-component-containers-comparison-slider-handle-size-width-narrow: var(--emu-semantic-sizing-medium);
  --emu-component-containers-comparison-slider-handle-size-width-wide: var(--emu-semantic-sizing-large);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-narrow: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-wide: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-comparison-slider-custom-handle-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-component-containers-tabs-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-component-containers-tabs-navigation-item-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-component-containers-tabs-navigation-item-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-component-containers-tabs-navigation-item-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-component-containers-tabs-navigation-item-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-component-containers-tabs-navigation-item-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-component-containers-tabs-navigation-item-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-tabs-navigation-item-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-active: var(--emu-semantic-border-width-thin);
  --emu-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-color-border-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-content-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-component-containers-tabs-content-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-component-containers-tabs-content-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-component-containers-tabs-content-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-component-containers-tabs-content-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-component-containers-tabs-content-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-component-containers-tabs-content-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-tabs-content-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-tabs-content-gap-horizontal-narrow: var(--emu-component-containers-container-gap-horizontal-narrow);
  --emu-component-containers-tabs-content-gap-horizontal-wide: var(--emu-component-containers-container-gap-horizontal-wide);
  --emu-component-containers-tabs-content-gap-vertical-narrow: var(--emu-component-containers-container-gap-vertical-narrow);
  --emu-component-containers-tabs-content-gap-vertical-wide: var(--emu-component-containers-container-gap-vertical-wide);
  --emu-component-containers-tabs-content-color-background-light: var(--emu-component-containers-container-color-background-light);
  --emu-component-containers-tabs-content-color-background-dark: var(--emu-component-containers-container-color-background-dark);
  --emu-component-containers-isi-header-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-body-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-open-content-size: 30vh;
  --emu-component-containers-isi-z-index: 400;
  --emu-component-containers-container-max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
  --emu-component-containers-container-padding-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-container-padding-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-container-padding-right-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-padding-right-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-padding-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-container-padding-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-container-padding-left-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-padding-left-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-container-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-top-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-top-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-bottom-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-bottom-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-dots-color-background-default-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-background-default-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-background-active-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-background-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-border-default-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-border-default-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-border-active-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-border-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-size-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-dots-size-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-image-container-size-narrow: 160px;
  --emu-component-containers-carousel-indicators-image-container-size-wide: 160px;
  --emu-component-containers-carousel-indicators-image-color-border-default-light: var(--emu-common-colors-grey-100);
  --emu-component-containers-carousel-indicators-image-color-border-default-dark: var(--emu-common-colors-grey-100);
  --emu-component-containers-carousel-indicators-image-color-border-active-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-image-color-border-active-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-image-border-width-all: var(--emu-common-border-width-thick);
  --emu-component-containers-carousel-indicators-image-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-image-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-text-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-light: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-dark: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-carousel-indicators-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-carousel-controls-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-color-border-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-border-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-controls-z-index: 100;
  --emu-component-containers-carousel-controls-icon-color-fill-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-containers-carousel-controls-icon-color-fill-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-containers-carousel-controls-icon-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-controls-icon-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-embed-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-embed-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-embed-player-vimeo-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-vimeo-global-text-typography-line-height: 1.7px;
  --emu-component-containers-embed-player-vimeo-color-background-light: #000;
  --emu-component-containers-embed-player-vimeo-color-background-dark: #000;
  --emu-component-containers-embed-player-vimeo-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-vimeo-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-vimeo-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-vimeo-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-vimeo-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-vimeo-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-vimeo-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-vimeo-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-vimeo-controls-global-padding: 7px;
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-tooltip-padding: 5px;
  --emu-component-containers-embed-player-vimeo-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-vimeo-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-vimeo-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-vimeo-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-youtube-global-text-typography-line-height: 1.7px;
  --emu-component-containers-embed-player-youtube-color-background-light: #000;
  --emu-component-containers-embed-player-youtube-color-background-dark: #000;
  --emu-component-containers-embed-player-youtube-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-youtube-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-youtube-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-youtube-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-youtube-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-youtube-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-youtube-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-youtube-controls-global-padding: 7px;
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-tooltip-padding: 5px;
  --emu-component-containers-embed-player-youtube-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-youtube-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-youtube-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-youtube-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-gallery-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-separator-content-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-sizing-width-narrow: 100%;
  --emu-component-containers-gallery-item-sizing-width-wide: calc(33.33% - 8px);
  --emu-component-containers-gallery-item-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-item-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-stage-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-gap-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-preview-container-gap-wide: var(--emu-common-sizing-xs);
  --emu-component-layers-modal-z-index: 600;
  --emu-component-layers-modal-scrim-color-background: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-layers-modal-body-sizing-width: 100%;
  --emu-component-layers-modal-body-sizing-max-width: 600px;
  --emu-component-layers-modal-body-padding-top-narrow: 20px;
  --emu-component-layers-modal-body-padding-top-wide: 15px;
  --emu-component-layers-modal-body-padding-right-narrow: 14px;
  --emu-component-layers-modal-body-padding-right-wide: 15px;
  --emu-component-layers-modal-body-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-bottom-wide: 11px;
  --emu-component-layers-modal-body-padding-left-narrow: 14px;
  --emu-component-layers-modal-body-padding-left-wide: 15px;
  --emu-component-layers-modal-body-color-background-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-background-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-color-border-light: var(--emu-common-colors-transparent);
  --emu-component-layers-modal-body-color-border-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-border-width-all: var(--emu-semantic-border-width-thin);
  --emu-component-layers-modal-actions-close-padding-top: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-padding-right: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-padding-bottom: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-padding-left: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-text-typography: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-layers-modal-actions-close-text-color-light: var(--emu-semantic-colors-secondary-grey-500);
  --emu-component-layers-modal-actions-close-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-actions-prompt-button-group-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-gap-wide: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-margin-top: var(--emu-semantic-spacing-horizontal);
}

html {
  -webkit-text-size-adjust: 100%;
}

html, body {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  font-family: var(--emu-semantic-font-families-body);
  text-align: left;
  margin: 0;
  padding: 0;
  line-height: 1.4;
}

html .xf-content-height, body .xf-content-height {
  margin: inherit;
}

html .experiencefragment, body .experiencefragment {
  padding: 0;
}

body {
  -webkit-overflow-scrolling: touch;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*, :before, :after {
  box-sizing: border-box;
}

.cmp-image__image, image {
  max-width: 100%;
  width: auto;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

.desktop-view {
  display: none;
}

@media (min-width: 1025px) {
  .desktop-view {
    display: block;
  }
}

.tablet-desktop-view {
  display: none;
}

@media (min-width: 768px) {
  .tablet-desktop-view {
    display: block;
  }
}

.tablet-mobile-view {
  display: block;
}

@media (min-width: 1025px) {
  .tablet-mobile-view {
    display: none;
  }
}

.mobile-view {
  display: block;
}

@media (min-width: 768px) {
  .mobile-view {
    display: none;
  }
}

.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.container--small {
  width: 1000px;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .container--small {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

.container--small-wrapper {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .container--small-wrapper {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 992px) {
  .container--small-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.container--small-wrapper .container--small {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
}

.container--large, .container--root {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

[data-component="text"].text--color-blue, [data-component="title"].text--color-blue {
  color: var(--emu-semantic-colors-primary-blue-100);
}

[data-component="text"].text--color-blue h1, [data-component="text"].text--color-blue h2, [data-component="text"].text--color-blue h3, [data-component="text"].text--color-blue h4, [data-component="text"].text--color-blue h5, [data-component="title"].text--color-blue h1, [data-component="title"].text--color-blue h2, [data-component="title"].text--color-blue h3, [data-component="title"].text--color-blue h4, [data-component="title"].text--color-blue h5 {
  color: inherit;
}

[data-component="text"].text--color-green, [data-component="title"].text--color-green {
  color: var(--emu-semantic-colors-primary-green-200);
}

[data-component="text"].text--color-green h1, [data-component="text"].text--color-green h2, [data-component="text"].text--color-green h3, [data-component="text"].text--color-green h4, [data-component="text"].text--color-green h5, [data-component="title"].text--color-green h1, [data-component="title"].text--color-green h2, [data-component="title"].text--color-green h3, [data-component="title"].text--color-green h4, [data-component="title"].text--color-green h5 {
  color: inherit;
}

.video-card {
  margin-bottom: 5px;
  position: relative;
}

@media (min-width: 768px) {
  .video-card {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

.video-card__play-text {
  background-image: url("resources/images/play-button.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: min(20%, 115px);
  background-color: var(--emu-common-colors-transparent);
  width: 100%;
  padding-bottom: min(20%, 115px);
  transform: translate(-50%, -50%);
}

@media (min-width: 768px) {
  .video-card__play-text {
    background-size: 115px;
    padding-bottom: 115px;
  }
}

.video-card__play-text .aaaem-text {
  width: 100%;
  color: var(--emu-semantic-colors-secondary-grey-300);
  z-index: var(--emu-common-other-z-index-layer);
  font-size: 30px;
  line-height: 30px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.video-card__play-text .aaaem-text p {
  font-weight: var(--emu-semantic-font-weight-extra-500);
  text-align: center;
}

@media (min-width: 992px) {
  .video-card__play-text .aaaem-text p {
    white-space: nowrap;
  }
}

.video-card__bg {
  min-height: 220px;
  display: flex;
}

.video-card__bg img {
  height: auto;
}

.video-card .button {
  position: static;
}

.video-card__trigger.cmp-button {
  width: 100%;
  height: 100%;
  z-index: var(--emu-common-other-z-index-layer);
  background-color: var(--emu-semantic-colors-none);
  border: var(--emu-semantic-colors-none);
  position: absolute;
  top: 0;
  left: 0;
}

.video-card__trigger.cmp-button:hover, .video-card__trigger.cmp-button:focus, .video-card__trigger.cmp-button:active, .video-card__trigger.cmp-button:visited {
  background-color: var(--emu-semantic-colors-none);
  border: var(--emu-semantic-colors-none);
}

@media (min-width: 992px) {
  .video-card__trigger.cmp-button {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#home-page .home-page-banner {
  margin-top: -65px;
  position: relative;
}

@media (min-width: 480px) {
  #home-page .home-page-banner {
    margin-top: -60px;
  }
}

@media (min-width: 768px) {
  #home-page .home-page-banner {
    margin-top: -75px;
  }
}

#home-page .home-page-banner__bg {
  min-height: 548px;
  background-position: 0 100%;
  background-size: 767px !important;
}

@media (min-width: 480px) {
  #home-page .home-page-banner__bg {
    min-height: 1020px;
    background-position: 50% 100%;
    background-size: auto !important;
    background-size: initial !important;
  }
}

@media (min-width: 768px) {
  #home-page .home-page-banner__bg {
    min-height: 942px;
  }
}

@media (min-width: 992px) {
  #home-page .home-page-banner__bg {
    min-height: 1020px;
    z-index: var(--emu-common-other-z-index-layer);
    background-color: #0000;
    position: relative;
  }
}

#home-page .home-page-banner__content {
  margin-top: var(--emu-common-spacing-none);
  min-height: 400px;
  background-color: var(--emu-common-colors-transparent);
  z-index: var(--emu-common-other-z-index-layer);
  position: relative;
  overflow: hidden;
}

@media (min-width: 480px) {
  #home-page .home-page-banner__content {
    margin-top: -109px;
  }
}

@media (min-width: 768px) {
  #home-page .home-page-banner__content {
    position: absolute;
    top: 460px;
    right: 0;
  }
}

@media (min-width: 992px) {
  #home-page .home-page-banner__content {
    z-index: var(--emu-common-other-z-index-layer);
    top: 390px;
  }
}

#home-page .home-page-banner__content h1 {
  font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
  line-height: var(--emu-semantic-line-heights-narrow-xxxl);
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-large);
  width: 320px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #home-page .home-page-banner__content h1 {
    font-size: var(--emu-semantic-font-sizes-wide-xxxl);
    line-height: var(--emu-semantic-line-heights-wide-xxxl);
    text-align: left;
    margin-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 992px) {
  #home-page .home-page-banner__content h1 {
    width: 384px;
    margin-bottom: 120px;
    margin-right: 35px;
    font-size: 94px;
    line-height: 110px;
  }

  #home-page .home-page-banner__content h1 .emphasis {
    margin-left: -53px;
    display: block;
  }
}

@media (min-width: 1025px) {
  #home-page .home-page-banner__content h1 {
    width: 496px;
    margin-bottom: 124px;
    font-size: 110px;
  }
}

#home-page .home-page-banner__content p {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  color: var(--emu-semantic-colors-secondary-grey-400);
  line-height: 22px;
  font-weight: var(--emu-semantic-font-weight-extra-500);
  width: 336px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  max-width: 100%;
}

@media (min-width: 768px) {
  #home-page .home-page-banner__content p {
    text-align: left;
    width: 376px;
    padding-right: 40px;
  }
}

@media (min-width: 992px) {
  #home-page .home-page-banner__content p {
    padding-right: var(--emu-common-spacing-none);
    width: 656px;
    margin-right: 11px;
    font-size: 32px;
    line-height: 38px;
  }
}

@media (min-width: 1025px) {
  #home-page .home-page-banner__content p {
    width: 752px;
  }
}

#home-page .home-page-banner__content sup {
  font-weight: var(--emu-common-font-weight-regular);
  font-size: 11px;
}

@media (min-width: 992px) {
  #home-page .home-page-banner__content sup {
    font-size: 16px;
    top: -14px;
    left: -4px;
  }
}

#home-page .home-page-banner__logo img {
  height: 75px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--emu-common-spacing-large);
  display: block;
}

@media (min-width: 768px) {
  #home-page .home-page-banner__logo img {
    margin-bottom: 20px;
    margin-right: 40px;
  }
}

@media (min-width: 992px) {
  #home-page .home-page-banner__logo img {
    height: 95px;
    margin-bottom: 75px;
    margin-right: 65px;
  }
}

@media (min-width: 1025px) {
  #home-page .home-page-banner__logo img {
    height: 127px;
    margin-bottom: 40px;
  }
}

#home-page .home-page-banner__bottom-asset {
  height: 190px;
  background-position: 50% 0;
  background-color: var(--emu-common-colors-transparent);
  margin-top: -185px;
  position: relative;
}

#home-page .home-page-banner__bottom-asset:after {
  content: "";
  width: 100%;
  height: 6px;
  background-color: var(--emu-semantic-colors-secondary-grey-100);
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (min-width: 480px) {
  #home-page .home-page-banner__bottom-asset {
    margin-top: -138px;
  }
}

@media (min-width: 768px) {
  #home-page .home-page-banner__bottom-asset {
    margin-top: -258px;
  }
}

@media (min-width: 992px) {
  #home-page .home-page-banner__bottom-asset {
    z-index: var(--emu-common-other-z-index-base);
    margin-top: -248px;
    position: relative;
  }
}

@media (min-width: 1025px) {
  #home-page .home-page-banner__bottom-asset {
    margin-top: -182px;
  }
}

#home-page .simple-steps {
  background-color: #ebebeb80;
  padding-top: 15px;
  position: relative;
}

@media (min-width: 480px) {
  #home-page .simple-steps {
    padding-top: 57px;
  }
}

@media (min-width: 1025px) {
  #home-page .simple-steps {
    padding-top: 15px;
  }
}

#home-page .simple-steps .aaaem-container {
  background-color: var(--emu-common-colors-transparent);
}

#home-page .simple-steps-title h2 {
  color: var(--emu-semantic-colors-primary-green-100);
  letter-spacing: 0;
  margin: var(--emu-common-spacing-none);
  font-weight: var(--emu-semantic-font-weight-extra-500);
}

@media (min-width: 768px) {
  #home-page .simple-steps-title h2 {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    line-height: var(--emu-semantic-line-heights-wide-xxl);
  }
}

#home-page .simple-steps-title h2 sup, #home-page .simple-steps-title h2 .emphasis {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: 0;
  font-weight: var(--emu-common-font-weight-regular);
  position: relative;
  top: -16px;
}

@media (min-width: 768px) {
  #home-page .simple-steps-title h2 sup, #home-page .simple-steps-title h2 .emphasis {
    top: -21px;
  }
}

#home-page .simple-steps-title h2 .emphasis {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  top: -8px;
}

@media (min-width: 768px) {
  #home-page .simple-steps-title h2 .emphasis {
    top: -4px;
  }
}

#home-page .simple-steps-container {
  padding-top: var(--emu-common-spacing-large);
}

@media (min-width: 768px) {
  #home-page .simple-steps-container {
    max-width: 95%;
    flex-wrap: wrap;
    display: flex;
  }
}

@media (min-width: 992px) {
  #home-page .simple-steps-container {
    max-width: 1000px;
    width: 95%;
  }
}

@media (min-width: 768px) {
  #home-page .simple-steps-container > div.container {
    width: 50%;
  }
}

@media (min-width: 992px) {
  #home-page .simple-steps-container > div.container {
    width: 25%;
  }
}

#home-page .simple-steps-container--inner {
  background-color: inherit;
}

#home-page .simple-steps-container--inner img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

#home-page .simple-steps-container--inner-text h2 {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-xxl);
  font-weight: var(--emu-semantic-font-weight-extra-500);
  color: var(--emu-semantic-colors-primary-green-100);
  margin-top: 24px;
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  #home-page .simple-steps-container--inner-text h2 {
    font-size: var(--emu-semantic-font-sizes-wide-large);
    line-height: var(--emu-semantic-line-heights-wide-medium);
  }
}

#home-page .simple-steps-footnote {
  padding-top: 34px;
  padding-bottom: var(--emu-common-spacing-large);
  max-width: 320px;
}

@media (min-width: 768px) {
  #home-page .simple-steps-footnote {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  #home-page .simple-steps-footnote {
    padding-top: 42px;
  }
}

#home-page .simple-steps-footnote p {
  color: var(--emu-semantic-colors-secondary-grey-500);
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  line-height: 22px;
}

#home-page .instruction-section {
  background-color: var(--emu-semantic-colors-secondary-grey-1200);
}

@media (min-width: 768px) {
  #home-page .instruction-section {
    display: flex;
  }

  #home-page .instruction-section > div {
    width: 50%;
    height: auto;
  }
}

#home-page .instruction-section__banner {
  margin-bottom: var(--emu-common-spacing-none);
  background-color: var(--emu-common-colors-transparent);
}

@media (min-width: 768px) {
  #home-page .instruction-section__banner, #home-page .instruction-section__banner .image {
    height: 100%;
  }
}

#home-page .instruction-section__banner .video-card__play-text {
  background-position: bottom;
  background-size: 20%;
}

@media (min-width: 768px) {
  #home-page .instruction-section__banner .video-card__play-text {
    background-size: 115px;
  }
}

#home-page .instruction-section__banner .video-card__play-text .aaaem-text {
  margin-top: var(--emu-common-spacing-none);
  position: static;
  transform: none;
}

@media (min-width: 1025px) {
  #home-page .instruction-section__banner .video-card__bg {
    height: auto;
  }
}

#home-page .instruction-section__contact {
  padding-top: var(--emu-common-spacing-xl);
  padding-bottom: 29px;
  padding-left: var(--emu-common-spacing-medium);
  padding-right: var(--emu-common-spacing-medium);
  background-color: var(--emu-common-colors-transparent);
  min-height: 285px;
}

@media (min-width: 768px) {
  #home-page .instruction-section__contact {
    padding-top: var(--emu-common-spacing-medium);
    padding-bottom: var(--emu-common-spacing-large);
  }
}

@media (min-width: 992px) {
  #home-page .instruction-section__contact {
    padding-top: var(--emu-common-border-radius-large);
  }
}

@media (min-width: 1025px) {
  #home-page .instruction-section__contact {
    padding-top: 80px;
  }
}

#home-page .instruction-section__contact-image img {
  width: 80px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #home-page .instruction-section__contact-image img {
    width: 30%;
  }
}

@media (min-width: 992px) {
  #home-page .instruction-section__contact-image img {
    width: auto;
  }
}

#home-page .instruction-section__contact-text {
  width: 72%;
  padding-top: var(--emu-common-spacing-large);
}

@media (min-width: 480px) {
  #home-page .instruction-section__contact-text {
    width: 42%;
  }
}

@media (min-width: 768px) {
  #home-page .instruction-section__contact-text {
    width: 69%;
    padding-top: 48px;
  }
}

@media (min-width: 992px) {
  #home-page .instruction-section__contact-text {
    width: 86%;
  }
}

@media (min-width: 1025px) {
  #home-page .instruction-section__contact-text {
    width: 68%;
  }
}

#home-page .instruction-section__contact-text h2 {
  color: var(--emu-semantic-colors-secondary-grey-300);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  font-weight: var(--emu-semantic-font-weight-extra-500);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  margin: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #home-page .instruction-section__contact-text h2 {
    line-height: 24px;
  }
}

@media (min-width: 992px) {
  #home-page .instruction-section__contact-text h2 {
    font-size: var(--emu-semantic-font-sizes-narrow-xxl);
    line-height: var(--emu-semantic-line-heights-narrow-xxl);
  }
}

#home-page .payment-record {
  padding-top: 60px;
  padding-bottom: var(--emu-common-spacing-large);
}

#home-page .payment-record-title, #home-page .payment-record-sub-title {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 768px) {
  #home-page .payment-record-title, #home-page .payment-record-sub-title {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#home-page .payment-record-title h3, #home-page .payment-record-title h4, #home-page .payment-record-sub-title h3, #home-page .payment-record-sub-title h4 {
  font-weight: var(--emu-semantic-font-weight-extra-500);
  margin: var(--emu-common-spacing-none);
}

#home-page .payment-record-title h3 {
  font-size: 26px;
  line-height: var(--emu-semantic-line-heights-narrow-xl);
  color: var(--emu-semantic-colors-primary-green-100);
  word-break: break-all;
}

@media (min-width: 768px) {
  #home-page .payment-record-title h3 {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    padding-top: 20px;
    line-height: 1;
  }
}

@media (min-width: 1024px) {
  #home-page .payment-record-title h3 {
    font-size: 38.4px;
  }
}

@media (min-width: 1367px) {
  #home-page .payment-record-title h3 {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
  }
}

#home-page .payment-record-title h3 sup {
  font-size: 18px;
}

@media (min-width: 768px) {
  #home-page .payment-record-title h3 sup {
    font-size: var(--emu-semantic-font-sizes-narrow-xxl);
    top: -10px;
  }
}

#home-page .payment-record-title h3 sup u {
  font-family: initial;
  font-size: 18px;
  text-decoration: none;
}

@media (min-width: 768px) {
  #home-page .payment-record-title h3 sup u {
    font-size: 29px;
  }
}

#home-page .payment-record-sub-title {
  margin-top: 30px;
}

#home-page .payment-record-sub-title h4 {
  color: var(--emu-semantic-colors-secondary-grey-300);
  font-size: 18px;
  line-height: 25px;
}

@media (min-width: 768px) {
  #home-page .payment-record-sub-title h4 {
    font-size: var(--emu-semantic-font-sizes-narrow-xxl);
    line-height: var(--emu-semantic-line-heights-wide-xxl);
  }
}

#home-page .payment-record-image {
  max-width: 95%;
  padding-top: var(--emu-common-spacing-large);
}

@media (min-width: 768px) {
  #home-page .payment-record-image {
    justify-content: space-around;
    display: flex;
  }
}

@media (min-width: 992px) {
  #home-page .payment-record-image {
    width: 1000px;
    grid-gap: calc(25% - 60px);
    justify-content: center;
    gap: calc(25% - 60px);
  }

  #home-page .payment-record-image:before, #home-page .payment-record-image:after {
    content: "";
    display: block;
  }
}

#home-page .payment-record-image > .image {
  margin-left: 10px;
}

#home-page .payment-record-image > .image img {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #home-page .payment-record-image > .image {
    margin-left: var(--emu-common-spacing-none);
  }
}

@media (min-width: 992px) {
  #home-page .payment-record-image > .image {
    min-width: 235px;
    position: relative;
  }

  #home-page .payment-record-image > .image:first-child {
    left: 25px;
  }

  #home-page .payment-record-image > .image:last-child {
    left: 5px;
  }
}

@media (min-width: 1024px) {
  #home-page .payment-record-image > .image:first-child {
    left: 10px;
  }
}

#home-page .payment-record-image-left, #home-page .payment-record-image-right {
  margin-bottom: 47px;
}

#home-page .payment-record-features-link-text {
  max-width: 566px;
  width: 85%;
  margin-top: 53px;
  margin-bottom: 60px;
  display: block;
}

@media (min-width: 768px) {
  #home-page .payment-record-features-link-text {
    margin-top: 10px;
  }
}

@media (min-width: 992px) {
  #home-page .payment-record-features-link-text {
    margin-bottom: 60px;
  }
}

#home-page .payment-record-features-link-text h2 {
  margin: var(--emu-common-spacing-none);
}

#home-page .payment-record-features-link-text h2 a {
  background-color: var(--emu-semantic-colors-primary-green-100);
  width: 100%;
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  color: var(--emu-common-colors-white);
  line-height: 30px;
  font-weight: var(--emu-semantic-font-weight-extra-500);
  border-radius: 3px;
  padding: 15px 25px;
  text-decoration: none;
  display: block;
}

#home-page .payment-record-references {
  max-width: 320px;
}

@media (min-width: 768px) {
  #home-page .payment-record-references {
    max-width: 700px;
  }
}

@media (min-width: 992px) {
  #home-page .payment-record-references {
    max-width: 100%;
  }
}

#home-page .payment-record-references p {
  margin: var(--emu-common-spacing-none);
  line-height: var(--emu-semantic-line-heights-wide-large);
  color: var(--emu-semantic-colors-secondary-grey-500);
}

#home-page .payment-record-references p sup u {
  font-family: initial;
  text-decoration: none;
}

#features-page .cmp-teaser {
  height: 401px;
}

@media (min-width: 768px) {
  #features-page .cmp-teaser {
    height: 475px;
  }
}

#features-page .cmp-teaser__title {
  width: 100%;
  padding-top: 112px;
  position: absolute;
  top: 80px;
  left: 0;
}

@media (min-width: 768px) {
  #features-page .cmp-teaser__title {
    padding-top: 216px;
    font-size: 44px;
  }
}

#features-page .cmp-teaser__teaser-section {
  z-index: var(--emu-common-other-z-index-layer);
  position: relative;
}

#features-page .cmp-teaser__image-section {
  width: 170px;
  z-index: var(--emu-common-other-z-index-layer);
  background: none;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: absolute;
  top: 292px;
  left: auto;
  right: 6px;
}

@media (min-width: 768px) {
  #features-page .cmp-teaser__image-section {
    top: 344px;
    right: 23px;
  }
}

@media (min-width: 992px) {
  #features-page .cmp-teaser__image-section {
    top: 341px;
    right: 0;
  }
}

@media (min-width: 1025px) {
  #features-page .cmp-teaser__image-section {
    top: 332px;
    left: 0;
    right: -870px;
  }
}

@media (min-width: 1280px) {
  #features-page .cmp-teaser__image-section {
    width: 1000px;
    flex-direction: column;
    align-items: flex-end;
    display: flex;
    left: 50%;
    right: auto;
    transform: translateX(-50%)translateX(-30px);
  }
}

#features-page .cmp-teaser__image-section .cmp-image__image {
  width: 100px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #features-page .cmp-teaser__image-section .cmp-image__image {
    width: 130px;
  }
}

#features-page .cmp-teaser__teaser-content {
  text-transform: none;
  font-style: normal;
  font-weight: normal;
}

#features-page .cmp-teaser__teaser-content p {
  margin-top: var(--emu-common-spacing-small);
  color: var(--emu-semantic-colors-secondary-grey-500);
  font-family: sans;
  font-size: 13px;
  line-height: 13px;
}

#features-page .cmp-teaser__teaser-content p .emphasis {
  padding-top: var(--emu-common-spacing-xxs);
  display: block;
}

#features-page .features__bg-container.aaaem-container {
  min-height: 1400px;
  background-color: var(--emu-semantic-colors-secondary-grey-1200);
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: -454px;
  padding-bottom: 56px;
  display: flex;
  position: relative;
  top: -454px;
  overflow: visible;
}

#features-page .features__bg-container.aaaem-container > div {
  width: 100%;
}

@media (min-width: 768px) {
  #features-page .features__bg-container.aaaem-container {
    margin-bottom: -415px;
    top: -415px;
  }
}

@media (min-width: 992px) {
  #features-page .features__bg-container.aaaem-container {
    margin-bottom: -340px;
    top: -340px;
  }
}

#features-page .features__bg {
  width: 100%;
  height: 100%;
  background-position: 51% 0;
  background-color: var(--emu-common-colors-transparent);
  position: absolute;
  top: 0;
  left: 0;
  background-size: auto !important;
}

@media (min-width: 768px) {
  #features-page .features__bg {
    background-position: 50% 0;
  }
}

#features-page .features__bg--bottom {
  background-position: 50% 100%;
  background-size: 100% !important;
}

#features-page .features__main-content .aaaem-container:not(.features__bg-container):not(.features__stat-wrapper) {
  background-color: var(--emu-common-colors-transparent);
}

#features-page .features__intro-container {
  z-index: var(--emu-common-other-z-index-layer);
  position: relative;
}

#features-page .features__intro-container, #features-page .features__bg-container, #features-page .features__intro-container .aaaem-container, #features-page .features__bg-container .aaaem-container {
  background-color: var(--emu-common-colors-transparent);
}

#features-page .features__intro-text h2, #features-page .features__intro-text h3 {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: 1;
  font-weight: var(--emu-semantic-font-weight-extra-500);
}

@media (min-width: 768px) {
  #features-page .features__intro-text h2, #features-page .features__intro-text h3 {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
  }
}

@media (min-width: 992px) {
  #features-page .features__intro-text h2, #features-page .features__intro-text h3 {
    font-size: var(--emu-semantic-font-sizes-wide-large);
  }
}

#features-page .features__intro-text h2 sup, #features-page .features__intro-text h3 sup {
  font-size: 8px;
  line-height: 0;
  font-weight: var(--emu-common-font-weight-regular);
  position: relative;
  top: -6px;
}

@media (min-width: 768px) {
  #features-page .features__intro-text h2 sup, #features-page .features__intro-text h3 sup {
    font-size: 12px;
    top: -8px;
  }
}

@media (min-width: 992px) {
  #features-page .features__intro-text h2 sup, #features-page .features__intro-text h3 sup {
    top: -6px;
  }
}

#features-page .features__intro-text h2 sup u, #features-page .features__intro-text h3 sup u {
  font-family: initial;
  font-size: 14px;
  text-decoration: none;
  position: relative;
  top: 3px;
}

@media (min-width: 768px) {
  #features-page .features__intro-text h2 sup u, #features-page .features__intro-text h3 sup u {
    top: 1px;
  }
}

#features-page .features__intro-text h2 {
  margin-top: 60px;
}

@media (min-width: 768px) {
  #features-page .features__intro-text h2 {
    margin-top: 90px;
  }
}

#features-page .features__intro-text h3 {
  font-weight: var(--emu-common-font-weight-bold);
  margin-top: var(--emu-common-spacing-large);
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #features-page .features__intro-text h3 {
    margin-top: 48px;
  }
}

#features-page .features__intro-grid-item {
  margin-top: var(--emu-common-spacing-medium);
  margin-left: var(--emu-common-spacing-medium);
  grid-gap: var(--emu-common-spacing-medium);
  gap: var(--emu-common-spacing-medium);
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  #features-page .features__intro-grid-item {
    margin-left: var(--emu-common-spacing-large);
  }
}

#features-page .features__intro-grid-item > .image {
  width: 50px;
}

@media (min-width: 768px) {
  #features-page .features__intro-grid-item > .image {
    width: 60px;
  }
}

#features-page .features__intro-grid-item > .image img {
  width: 100%;
}

#features-page .features__intro-grid-item > .text {
  flex: 1;
}

#features-page .features__intro-grid-text h4 {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: 1;
  font-weight: var(--emu-semantic-font-weight-extra-500);
}

@media (min-width: 768px) {
  #features-page .features__intro-grid-text h4 {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
  }
}

@media (min-width: 992px) {
  #features-page .features__intro-grid-text h4 {
    font-size: var(--emu-semantic-font-sizes-wide-large);
  }
}

#features-page .features__intro-disclaimer {
  margin-top: 43px;
}

#features-page .features__intro-disclaimer p {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  text-indent: -5px;
  font-weight: var(--emu-semantic-font-weight-extra-500);
  color: var(--emu-semantic-colors-secondary-grey-500);
}

@media (min-width: 768px) {
  #features-page .features__intro-disclaimer p {
    text-indent: -7px;
    font-size: 17.6px;
    line-height: 16px;
  }
}

@media (min-width: 992px) {
  #features-page .features__intro-disclaimer p {
    font-size: 19.2px;
  }
}

#features-page .features__intro-disclaimer p sup {
  font-family: initial;
  font-size: 9px;
  line-height: 0;
  top: -4px;
}

@media (min-width: 768px) {
  #features-page .features__intro-disclaimer p sup {
    font-size: 13px;
    top: -7px;
    left: -2px;
  }
}

@media (min-width: 992px) {
  #features-page .features__intro-disclaimer p sup {
    font-size: 14.4px;
    top: -8px;
    left: 0;
  }
}

#features-page .features__stat-wrapper--fast-insertion {
  background-color: var(--emu-semantic-colors-secondary-grey-1200);
  padding-top: var(--emu-common-spacing-xl);
  background-position: 50% 0;
  margin-bottom: -160px;
  padding-bottom: 48px;
  position: relative;
  top: -160px;
  background-size: 100% !important;
}

@media (min-width: 768px) {
  #features-page .features__stat-wrapper--fast-insertion {
    margin-bottom: -100px;
    padding-top: 160px;
    top: -100px;
  }
}

@media (min-width: 992px) {
  #features-page .features__stat-wrapper--fast-insertion {
    margin-bottom: -160px;
    padding-top: 226px;
    padding-bottom: 34px;
    top: -160px;
  }
}

#features-page .features__stat-container {
  z-index: var(--emu-common-other-z-index-layer);
  position: relative;
}

#features-page .features__stat-container--surgeon-recommendation {
  top: 12px;
}

#features-page .features__stat-container--efficient-delivery {
  z-index: var(--emu-common-other-z-index-overlay);
  padding-bottom: 128px;
}

@media (min-width: 768px) {
  #features-page .features__stat-container--efficient-delivery {
    padding-top: 32px;
    padding-bottom: 48px;
  }

  #features-page .features__stat-container--efficient-delivery .features__stat-text p {
    padding-right: var(--emu-common-spacing-none);
  }
}

#features-page .features__stat-title h4 {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  line-height: var(--emu-semantic-line-heights-narrow-xl);
  color: var(--emu-semantic-colors-primary-blue-100);
  font-weight: var(--emu-semantic-font-weight-extra-500);
  margin-top: 10px;
  margin-bottom: 34px;
}

@media (min-width: 768px) {
  #features-page .features__stat-title h4 {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: var(--emu-semantic-line-heights-wide-xl);
    margin-top: 10px;
    margin-bottom: 50px;
  }
}

@media (min-width: 992px) {
  #features-page .features__stat-title h4 {
    font-size: 36.8px;
    line-height: 40.48px;
  }
}

#features-page .features__stat-text p {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  color: var(--emu-semantic-colors-secondary-grey-500);
  line-height: 1;
  font-weight: var(--emu-semantic-font-weight-extra-500);
  margin-bottom: var(--emu-common-spacing-large);
}

@media (min-width: 768px) {
  #features-page .features__stat-text p {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    padding-right: 160px;
  }
}

@media (min-width: 992px) {
  #features-page .features__stat-text p {
    font-size: var(--emu-semantic-font-sizes-wide-large);
  }
}

#features-page .features__stat-text p sup {
  font-size: 8px;
  font-weight: var(--emu-common-font-weight-regular);
  top: -5px;
}

#features-page .features__stat-text p sup u {
  font-family: initial;
  text-decoration: none;
}

@media (min-width: 768px) {
  #features-page .features__stat-text p sup {
    font-size: 12px;
    top: -7px;
  }
}

#features-page .features__stat-text img {
  width: 80px;
  margin-right: var(--emu-common-spacing-small);
  display: inline-block;
}

@media (min-width: 768px) {
  #features-page .features__stat-text img {
    width: auto;
    vertical-align: middle;
    position: relative;
    top: -8px;
  }
}

#features-page .features__stat-img-text-container {
  grid-gap: 20px;
  background-color: var(--emu-common-colors-transparent);
  align-items: center;
  gap: 20px;
  margin-bottom: 45px;
  display: flex;
}

@media (min-width: 768px) {
  #features-page .features__stat-img-text-container {
    width: 680px;
    margin-top: 48px;
  }
}

#features-page .features__stat-img-text-container > .image {
  width: 140px;
}

@media (min-width: 768px) {
  #features-page .features__stat-img-text-container > .image {
    width: 188px;
  }
}

#features-page .features__stat-img-text-container > .text {
  flex: 1;
}

#features-page .features__stat-img-text p {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  color: var(--emu-semantic-colors-primary-green-200);
  line-height: 1;
  font-weight: var(--emu-semantic-font-weight-extra-500);
  margin: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #features-page .features__stat-img-text p {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
  }
}

@media (min-width: 992px) {
  #features-page .features__stat-img-text p {
    font-size: var(--emu-semantic-font-sizes-wide-large);
  }
}

#features-page .features__stat-img-text p sup {
  font-size: 8px;
  font-weight: var(--emu-common-font-weight-regular);
  top: -6px;
}

#features-page .features__stat-img-text p sup u {
  font-family: initial;
  text-decoration: none;
}

@media (min-width: 768px) {
  #features-page .features__stat-img-text p sup {
    font-size: 12px;
  }
}

#features-page .features__stat-disclaimer p {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  font-weight: var(--emu-semantic-font-weight-extra-500);
  color: var(--emu-semantic-colors-secondary-grey-500);
  margin-top: var(--emu-common-spacing-none);
  text-indent: -5px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  #features-page .features__stat-disclaimer p {
    font-size: 17.6px;
  }
}

@media (min-width: 992px) {
  #features-page .features__stat-disclaimer p {
    font-size: 19.2px;
  }
}

#features-page .features__stat-disclaimer p sup {
  font-family: initial;
  font-size: 9px;
}

@media (min-width: 768px) {
  #features-page .features__stat-disclaimer p sup {
    font-size: 13.2px;
    top: -8px;
    left: -2px;
  }
}

@media (min-width: 992px) {
  #features-page .features__stat-disclaimer p sup {
    font-size: 14.4px;
  }
}

#features-page .features__references {
  width: 1024px;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 102px;
  padding-bottom: 48px;
}

@media (min-width: 768px) {
  #features-page .features__references {
    padding-top: 96px;
    padding-bottom: 80px;
  }
}

#features-page .features__references p {
  font-weight: var(--emu-semantic-font-weight-extra-500);
  font-size: var(--emu-common-font-sizes-narrow-medium);
  color: #464646cc;
  line-height: 16.8px;
}

@media (min-width: 768px) {
  #features-page .features__references p {
    font-size: 16px;
    line-height: 1;
  }
}

#features-page .features__references b {
  font-weight: var(--emu-semantic-font-weight-bold);
}

#faq-page .cmp-teaser {
  background-position: 62% 100%;
  margin-top: -75px;
  margin-bottom: 75px;
}

@media (min-width: 768px) {
  #faq-page .cmp-teaser {
    height: 405px;
    background-position: 50% 100%;
  }
}

#faq-page .cmp-teaser__title {
  padding-top: 160px;
}

@media (min-width: 768px) {
  #faq-page .cmp-teaser__title {
    padding-top: 80px;
  }
}

#faq-page p {
  margin: var(--emu-common-sizing-none);
}

#faq-page .faq-reference {
  width: var(--emu-semantic-sizing-breakpoints-large);
  max-width: 95%;
  padding-top: var(--emu-common-spacing-xl);
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 48px;
}

#faq-page .faq-reference p {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  font-weight: var(--emu-semantic-font-weight-extra-500);
  color: #464646cc;
}

@media (min-width: 768px) {
  #faq-page .faq-reference {
    padding-top: 112px;
    padding-bottom: 96px;
  }

  #faq-page .faq-reference p {
    font-size: var(--emu-common-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-narrow-large);
  }
}

#resources-page p {
  margin: var(--emu-common-spacing-none);
}

#resources-page .video-card--main .video-card__bg {
  min-height: 250px;
}

@media (min-width: 768px) {
  #resources-page .video-card--main .video-card__bg {
    min-height: var(--emu-common-sizing-none);
  }
}

#resources-page .resources-card__wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  display: flex;
}

@media (min-width: 768px) {
  #resources-page .resources-card__wrapper {
    flex-direction: row;
    align-items: flex-end;
    margin-left: auto;
    margin-right: auto;
    padding-top: 87px;
  }
}

@media (min-width: 992px) {
  #resources-page .resources-card__wrapper {
    max-width: 78%;
    width: 100%;
  }
}

#resources-page .resources-card__wrapper > .card {
  width: 100%;
}

@media (min-width: 768px) {
  #resources-page .resources-card__wrapper > .card {
    width: 33.33%;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  #resources-page .resources-card__wrapper > .card:first-child .emu-card {
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    display: flex;
  }
}

@media (min-width: 768px) {
  #resources-page .resources-card__wrapper > .card:first-child .emu-card .button {
    min-width: 200px;
  }

  #resources-page .resources-card__wrapper > .card:first-child .emu-card img {
    width: 100%;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  #resources-page .resources-card__wrapper > .card:first-child .emu-card img {
    width: 300px;
    max-width: none;
    position: relative;
    bottom: -14px;
    left: 23px;
  }
}

@media (min-width: 768px) and (min-width: 1025px) {
  #resources-page .resources-card__wrapper > .card:first-child .emu-card img {
    min-width: 583px;
    bottom: -30px;
    left: -50px;
  }
}

#resources-page .resources-card__wrapper > .card img {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) {
  #resources-page .resources-video__bottom-section {
    min-height: 280px;
    flex-direction: row;
    display: flex;
  }

  #resources-page .resources-video__bottom-section > div {
    width: 50%;
  }
}

#resources-page .resources-video__bottom-section > .container:last-child .resources-video__container {
  margin-bottom: var(--emu-common-spacing-none);
}

#resources-page .resources-video__bottom-image {
  height: 122px;
  background-color: var(--emu-semantic-colors-none);
  width: 100%;
  background-position: 50% 100%;
  position: absolute;
  bottom: 9px;
}

@media (min-width: 768px) {
  #resources-page .resources-video__bottom-image {
    bottom: 38px;
  }
}

@media (min-width: 992px) {
  #resources-page .resources-video__bottom-image {
    bottom: 48px;
  }
}

@media (min-width: 1025px) {
  #resources-page .resources-video__bottom-image {
    bottom: 5px;
  }
}

#resources-page .resources-video__section {
  margin-top: 40px;
  margin-bottom: 10px;
  padding-bottom: 40px;
  position: relative;
}

@media (min-width: 768px) {
  #resources-page .resources-video__section {
    margin-bottom: var(--emu-common-spacing-none);
  }

  #resources-page [data-id="resource-modal-two"] .modal-content, #resources-page [data-id="resource-modal-three"] .modal-content {
    padding-left: 30px;
    padding-right: 30px;
  }
}

#error-page .error-text {
  max-width: 1055px;
  width: 95%;
  margin: 164px auto 144px;
}

#error-page .error-text h1, #error-page .error-text p {
  color: var(--emu-semantic-colors-primary-blue-300);
  font-weight: var(--emu-semantic-font-weight-extra-500);
  margin: var(--emu-common-spacing-none);
}

#error-page .error-text h1 {
  font-size: var(--emu-common-sizing-large);
  line-height: 70.4px;
}

#error-page .error-text p {
  font-size: var(--emu-common-sizing-medium);
  line-height: 40px;
}

.aaaem-accordion {
  max-width: 95%;
  width: 1135px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.aaaem-accordion__header {
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-blue-100);
  margin-top: 5px;
  transition-delay: .35s;
}

.aaaem-accordion__header.-ba-is-active {
  padding-bottom: var(--emu-common-spacing-medium);
  transition-delay: var(--emu-common-other-time-delay-none);
}

.aaaem-accordion__header.-ba-is-active .aaaem-accordion__icon:before {
  display: none;
}

.aaaem-accordion__header.-ba-is-active .aaaem-accordion__icon:after {
  width: 2px;
}

@media (min-width: 768px) {
  .aaaem-accordion__header.-ba-is-active {
    padding-bottom: var(--emu-common-spacing-large);
  }
}

.aaaem-accordion__title {
  line-height: var(--emu-common-line-heights-narrow-large);
  font-weight: var(--emu-common-font-weight-bold);
  color: var(--emu-semantic-colors-primary-blue-100);
}

.aaaem-accordion__icon {
  top: -27px;
  right: -22px;
}

.aaaem-accordion__icon:before, .aaaem-accordion__icon:after {
  transition: none;
}

@media (min-width: 768px) {
  .aaaem-accordion__title {
    line-height: 26px;
  }

  .aaaem-accordion__icon {
    width: 20px;
    height: 20px;
    top: -6px;
    left: -4px;
    right: 0;
  }

  .aaaem-accordion__icon:before, .aaaem-accordion__icon:after {
    width: 4.5px;
  }
}

dd .js-badger-accordion-panel-inner .aaaem-text h3, dd .js-badger-accordion-panel-inner .aaaem-text p {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: var(--emu-common-line-heights-wide-large);
}

dd .js-badger-accordion-panel-inner .aaaem-text h3 {
  font-weight: var(--emu-common-font-weight-bold);
}

dd .js-badger-accordion-panel-inner .aaaem-text p {
  padding-bottom: 24px;
}

dd .js-badger-accordion-panel-inner .aaaem-text p sup {
  font-size: 8px;
  position: relative;
  top: -6px;
}

dd .js-badger-accordion-panel-inner .aaaem-text p .emphasis {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: 15px;
  display: block;
}

dd .js-badger-accordion-panel-inner .aaaem-text p a {
  color: var(--emu-semantic-colors-primary-blue-100);
}

dd .js-badger-accordion-panel-inner .aaaem-text p a:hover, dd .js-badger-accordion-panel-inner .aaaem-text p a:focus, dd .js-badger-accordion-panel-inner .aaaem-text p a:active {
  color: var(--emu-semantic-colors-primary-blue-400);
}

dd .js-badger-accordion-panel-inner .aaaem-text img {
  vertical-align: top;
  margin-right: var(--emu-common-spacing-xs);
  display: inline-block;
  position: relative;
  top: -5px;
}

@media (min-width: 768px) {
  dd .js-badger-accordion-panel-inner .aaaem-text h3, dd .js-badger-accordion-panel-inner .aaaem-text p {
    font-size: var(--emu-semantic-font-sizes-wide-large);
  }

  dd .js-badger-accordion-panel-inner .aaaem-text p .emphasis {
    font-size: var(--emu-common-font-sizes-wide-medium);
    line-height: var(--emu-common-line-heights-wide-large);
  }

  dd .js-badger-accordion-panel-inner .aaaem-text p sup {
    font-size: 12px;
    top: -7px;
  }
}

.aaaem-card__title-wrapper {
  display: none;
}

.aaaem-card__body [data-component="text"] {
  font-size: 20px;
  line-height: var(--emu-common-line-heights-wide-medium);
  margin-top: var(--emu-common-spacing-medium);
  color: var(--emu-semantic-colors-secondary-grey-500);
  margin-bottom: 3px;
}

.aaaem-card__body [data-component="text"] p {
  font-weight: var(--emu-semantic-font-weight-extra-500);
}

.aaaem-card .button {
  justify-content: center;
  display: flex;
}

.aaaem-card .button .aaaem-button {
  padding-top: var(--emu-common-spacing-medium);
  padding-bottom: var(--emu-common-spacing-medium);
  margin-top: var(--emu-common-spacing-medium);
  max-width: 200px;
  width: 100%;
}

.aaaem-card .button .aaaem-button span {
  margin-left: auto;
  margin-right: auto;
}

#onetrust-consent-sdk {
  font-family: var(--emu-common-font-families-sans);
}

.cmp-experiencefragment--footer {
  background-color: var(--emu-semantic-colors-secondary-grey-100);
  padding-top: var(--emu-common-spacing-xl);
  padding-bottom: var(--emu-common-spacing-large);
}

.cmp-experiencefragment--footer .aaaem-container {
  background-color: var(--emu-semantic-colors-none);
}

.cmp-experiencefragment--footer .footer__top-section, .cmp-experiencefragment--footer .footer__bottom-section {
  width: var(--emu-semantic-sizing-breakpoints-large);
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.cmp-experiencefragment--footer .footer__top-section {
  padding-bottom: 20px;
}

.cmp-experiencefragment--footer .footer__top-section .aaaem-image {
  max-width: 125px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .cmp-experiencefragment--footer .footer__top-section .aaaem-image {
    margin-bottom: var(--emu-common-spacing-none);
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    width: 180px;
  }
}

.cmp-experiencefragment--footer .footer__top-section .aaaem-image img {
  width: 100%;
}

.cmp-experiencefragment--footer .footer__top-section .aaaem-text {
  font-size: 14px;
  line-height: 20px;
}

.cmp-experiencefragment--footer .footer__top-section .aaaem-text ul {
  padding-left: var(--emu-common-spacing-none);
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  margin-left: min(50px, 2vw);
}

.cmp-experiencefragment--footer .footer__top-section .aaaem-text li {
  margin: var(--emu-common-spacing-none);
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  list-style: none;
}

.cmp-experiencefragment--footer .footer__top-section .aaaem-text li a {
  font-weight: var(--emu-semantic-font-weight-extra-500);
  color: var(--emu-semantic-colors-secondary-grey-200);
  text-decoration: none;
}

@media (min-width: 768px) {
  .cmp-experiencefragment--footer .footer__top-section {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    display: flex;
  }

  .cmp-experiencefragment--footer .footer__top-section .text {
    width: 100%;
  }

  .cmp-experiencefragment--footer .footer__top-section .text .aaaem-text {
    line-height: 14px;
  }

  .cmp-experiencefragment--footer .footer__top-section .text .aaaem-text ul {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
  }

  .cmp-experiencefragment--footer .footer__top-section .text .aaaem-text ul li {
    text-align: left;
  }
}

@media (min-width: 1025px) {
  .cmp-experiencefragment--footer .footer__top-section {
    margin-left: auto;
  }
}

.cmp-experiencefragment--footer .footer__bottom-section {
  width: 1026px;
}

.cmp-experiencefragment--footer .footer__bottom-section .aaaem-image {
  max-width: 200px;
  width: 100%;
}

.cmp-experiencefragment--footer .footer__bottom-section .aaaem-text {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  color: var(--emu-semantic-colors-secondary-grey-800);
  line-height: 15px;
}

.cmp-experiencefragment--footer .footer__bottom-section .aaaem-text a {
  color: #337ab7;
  text-decoration: none;
}

.cmp-experiencefragment--footer .footer__bottom-section .aaaem-text a:hover, .cmp-experiencefragment--footer .footer__bottom-section .aaaem-text a:focus, .cmp-experiencefragment--footer .footer__bottom-section .aaaem-text a:active {
  color: #23527c;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .cmp-experiencefragment--footer .footer__bottom-section .aaaem-text {
    font-size: var(--emu-common-font-sizes-wide-medium);
    line-height: 20px;
  }
}

.cmp-experiencefragment--footer .footer__bottom-section .aaaem-text p {
  margin: var(--emu-common-spacing-none);
  font-weight: var(--emu-semantic-font-weight-extra-500);
}

.header {
  width: 100%;
  opacity: .9;
}

@media (min-width: 768px) {
  .header {
    opacity: 1;
  }
}

.header__content {
  z-index: var(--emu-common-other-z-index-header);
  position: relative;
}

.header__eyebrow-container {
  background-color: var(--emu-semantic-colors-secondary-grey-100);
  justify-content: center;
  display: flex;
  position: relative;
}

.header__eyebrow-container > div:first-child {
  width: 100%;
}

.header__eyebrow-container .header__brand-menu-trigger {
  font-size: 10.5px;
  line-height: var(--emu-semantic-line-heights-narrow-large);
  font-weight: var(--emu-semantic-font-weight-extra-600);
}

.header__eyebrow-container .header__brand-menu-trigger span, .header__eyebrow-container .header__brand-menu-trigger:active span, .header__eyebrow-container .header__brand-menu-trigger:focus span, .header__eyebrow-container .header__brand-menu-trigger:hover span {
  color: var(--emu-semantic-colors-primary-blue-200);
}

.header__main-menu {
  background-color: inherit;
  padding-top: var(--emu-common-spacing-small);
  padding-bottom: var(--emu-common-spacing-small);
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
}

.header__main-menu-logo img {
  max-height: 45px;
  height: 100%;
}

.header__main-menu .button {
  margin-right: 5px;
  display: flex;
}

.header__main-menu .button .aaaem-button, .header__main-menu .button .aaaem-button:hover, .header__main-menu .button .aaaem-button:focus, .header__main-menu .button .aaaem-button:active {
  background-color: var(--emu-common-colors-transparent);
  padding: var(--emu-common-spacing-none);
  margin: var(--emu-common-spacing-none);
  border: none;
  position: relative;
}

.header__main-menu .button .aaaem-button span, .header__main-menu .button .aaaem-button:hover span, .header__main-menu .button .aaaem-button:focus span, .header__main-menu .button .aaaem-button:active span {
  height: 3px;
  width: 20px;
  background-color: var(--emu-common-colors-white);
  margin-top: var(--emu-common-spacing-small);
  margin-bottom: var(--emu-common-spacing-small);
}

.header__main-menu .button .aaaem-button span:after, .header__main-menu .button .aaaem-button span:before, .header__main-menu .button .aaaem-button:hover span:after, .header__main-menu .button .aaaem-button:hover span:before, .header__main-menu .button .aaaem-button:focus span:after, .header__main-menu .button .aaaem-button:focus span:before, .header__main-menu .button .aaaem-button:active span:after, .header__main-menu .button .aaaem-button:active span:before {
  content: "";
  width: inherit;
  height: inherit;
  background-color: inherit;
  position: absolute;
  left: 0;
}

.header__main-menu .button .aaaem-button span:after, .header__main-menu .button .aaaem-button:hover span:after, .header__main-menu .button .aaaem-button:focus span:after, .header__main-menu .button .aaaem-button:active span:after {
  bottom: 2px;
}

.header__main-menu .button .aaaem-button span:before, .header__main-menu .button .aaaem-button:hover span:before, .header__main-menu .button .aaaem-button:focus span:before, .header__main-menu .button .aaaem-button:active span:before {
  top: 2px;
}

.header__main-menu-wrapper {
  background-color: #009bdee6;
  position: relative;
}

.header__main-menu-wrapper > div:first-child {
  max-width: 695px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.header__main-menu-wrapper > div:first-child .header__main-menu-wrapper-inner {
  background-color: var(--emu-common-colors-transparent);
}

.header__brand-menu {
  background-color: inherit;
  justify-content: center;
  display: flex;
  position: relative;
}

.header__brand-menu .aaaem-text {
  background-color: var(--emu-semantic-colors-secondary-grey-1100);
  transition: max-height var(--emu-common-other-time-duration-instant) linear;
  width: 100%;
  z-index: var(--emu-common-other-z-index-header);
  max-height: var(--emu-common-sizing-none);
  position: absolute;
  top: 46px;
  left: 0;
  overflow: hidden;
}

.header__brand-menu .aaaem-text ul {
  padding: var(--emu-common-spacing-none);
  margin: var(--emu-common-spacing-none);
}

.header__brand-menu .aaaem-text ul li > a {
  font-size: 9.5px;
  line-height: 12px;
  font-weight: var(--emu-semantic-font-weight-extra-500);
  color: var(--emu-semantic-colors-secondary-grey-900);
  width: 100%;
  padding: 10px 20px;
  text-decoration: none;
  display: block;
}

.header__brand-menu .aaaem-text ul li > a:hover {
  background-color: var(--emu-semantic-colors-secondary-grey-1000);
}

.header__brand-menu-links-wrapper.js-toggle-on {
  max-height: 400px;
}

@media (min-width: 768px) {
  .header__main-menu {
    background-color: inherit;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: var(--emu-common-spacing-none);
  }

  .header__main-menu .button {
    display: none;
  }

  .header__main-menu-wrapper-inner {
    align-items: center;
    display: flex;
  }

  .header__main-menu-logo img {
    max-height: 55px;
  }

  .header .emu-navigation__content-wrapper nav {
    background-color: var(--emu-common-colors-transparent);
    margin-left: 30px;
  }

  .header__eyebrow-container > div:first-child {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .header__eyebrow-container .aaaem-button {
    white-space: nowrap;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 5px;
  }

  .header__eyebrow-container-desktop-links {
    height: 100%;
    align-items: center;
    display: flex;
  }

  .header__eyebrow-container-desktop-links ul {
    margin: var(--emu-common-spacing-none);
    padding: var(--emu-common-spacing-none);
    display: flex;
  }

  .header__eyebrow-container-desktop-links ul li {
    display: inherit;
    list-style: none;
  }

  .header__eyebrow-container-desktop-links ul li a {
    font-size: 10.5px;
    line-height: var(--emu-semantic-line-heights-narrow-large);
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: var(--emu-common-spacing-xxs);
    padding-right: var(--emu-common-spacing-xxs);
    font-weight: var(--emu-semantic-font-weight-extra-600);
    color: var(--emu-semantic-colors-secondary-grey-900);
    white-space: nowrap;
    text-decoration: none;
  }

  .header__eyebrow-container-desktop-links ul > li:last-child a {
    color: var(--emu-common-colors-white);
    background-color: var(--emu-semantic-colors-primary-green-200);
    padding-left: 14px;
    padding-right: 10px;
  }

  .header__brand-menu .aaaem-text {
    background-color: var(--emu-semantic-colors-secondary-grey-1100);
    min-width: 325px;
    top: 46px;
  }
}

@media (min-width: 992px) {
  .header__eyebrow-container-desktop-links ul li a {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-medium);
  }

  .header__eyebrow-container-desktop-links ul > li:last-child a {
    padding-left: 35px;
    padding-right: 35px;
  }

  .header__eyebrow-container .aaaem-button {
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-medium);
    margin-right: 5px;
  }

  .header__main-menu-logo img {
    margin-left: 5px;
  }
}

.has-brand-menu-open .header__main-menu-wrapper {
  visibility: hidden;
}

@media (min-width: 768px) {
  .has-brand-menu-open .header__main-menu-wrapper {
    visibility: visible;
  }
}

header.experiencefragment {
  z-index: var(--emu-common-other-z-index-header);
  position: relative;
}

header.experiencefragment + div {
  z-index: var(--emu-common-other-z-index-base);
}

.header__nav-wrapper {
  max-height: var(--emu-common-sizing-none);
  transition: max-height var(--emu-common-other-time-duration-instant) linear;
  width: 100%;
  background-color: var(--emu-semantic-colors-secondary-grey-100);
  position: absolute;
  left: 0;
  overflow: hidden;
}

.header__nav-wrapper .emu-navigation__content-wrapper {
  position: static;
}

.header__nav-wrapper .emu-navigation__content-wrapper .emu-navigation ul li a {
  font-size: 10.5px;
  line-height: var(--emu-semantic-line-heights-narrow-large);
  font-weight: var(--emu-semantic-font-weight-extra-500);
}

.header__nav-wrapper .emu-navigation__item--active a, .header__nav-wrapper .emu-navigation__item--active a:hover, .header__nav-wrapper .emu-navigation__item--active a:focus, .header__nav-wrapper .emu-navigation__item--active a:active {
  color: var(--emu-semantic-colors-primary-green-200);
}

@media (min-width: 768px) {
  .header__nav-wrapper .emu-navigation__item--active a, .header__nav-wrapper .emu-navigation__item--active a:hover, .header__nav-wrapper .emu-navigation__item--active a:focus, .header__nav-wrapper .emu-navigation__item--active a:active {
    color: var(--emu-common-colors-white);
    text-decoration: underline;
  }
}

.header__nav-wrapper-mobile-nav ul .emu-navigation__item--active a, .header__nav-wrapper-mobile-nav ul .emu-navigation__item--active a:hover, .header__nav-wrapper-mobile-nav ul .emu-navigation__item--active a:active, .header__nav-wrapper-mobile-nav ul .emu-navigation__item--active a:focus {
  color: var(--emu-component-lists-navigation-banner-item-text-color-default-light);
}

.header__nav-wrapper-mobile-nav ul > li:last-child a, .header__nav-wrapper-mobile-nav ul > li:last-child a:hover {
  background-color: var(--emu-semantic-colors-primary-green-200);
  color: var(--emu-common-colors-white);
}

.header__nav-wrapper.js-toggle-on {
  max-height: 400px;
}

@media (min-width: 768px) {
  .header__nav-wrapper {
    max-height: none;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: var(--emu-common-colors-transparent);
    position: static;
  }

  .header__nav-wrapper .emu-navigation__content-wrapper .emu-navigation ul li a {
    color: var(--emu-common-colors-white);
    font-size: 15px;
    line-height: 16px;
  }

  .header__nav-wrapper .emu-navigation__content-wrapper .emu-navigation ul li a:active {
    text-decoration: underline;
  }

  .header__nav-wrapper .emu-navigation__content-wrapper .header__nav-wrapper-mobile-nav {
    display: none;
  }

  .header__nav-wrapper.js-toggle-on {
    max-height: none;
    height: auto;
  }
}

.emu-modal[data-component="modal"] .modal-wrapper {
  opacity: 0;
  pointer-events: none;
  transition: all var(--emu-common-other-time-transition-base);
  justify-content: center;
  align-items: center;
  display: flex;
}

.emu-modal[data-component="modal"] .modal-wrapper.is-open {
  opacity: 1;
  pointer-events: initial;
}

.emu-modal[data-component="modal"] .modal-wrapper.is-open .modal-content {
  opacity: 1;
  transform: translateY(0);
}

.emu-modal[data-component="modal"] .modal-content {
  border: var(--emu-common-border-width-thin) solid var(--emu-common-colors-black);
  width: auto;
  transition: all var(--emu-common-other-time-transition-base);
  opacity: 0;
  border-radius: 6px;
  margin: auto;
  position: relative;
  left: -6px;
  transform: translateY(-100px);
  box-shadow: 0 3px 9px #00000080;
}

@media (min-width: 768px) {
  .emu-modal[data-component="modal"] .modal-content {
    width: var(--emu-component-layers-modal-body-sizing-max-width);
  }
}

.emu-modal[data-component="modal"] .modal-content .aaaem-embed__oembed {
  padding: var(--emu-common-spacing-none);
}

.emu-modal[data-component="modal"] .modal-content .aaaem-embed__oembed iframe {
  width: auto;
  height: auto;
  position: static;
}

@media (min-width: 768px) {
  .emu-modal[data-component="modal"] .modal-content .aaaem-embed__oembed iframe {
    width: 100%;
    height: 350px;
  }
}

.emu-modal[data-component="modal"] .close {
  font-weight: var(--emu-semantic-font-weight-extra-500);
  opacity: 1;
  margin-left: auto;
  position: static;
}

.emu-modal[data-component="modal"] .close span {
  display: none;
}

.emu-modal[data-component="modal"] .close:after {
  content: "X";
  font-size: 15px;
  line-height: var(--emu-common-line-heights-wide-large);
  display: block;
}

.js-modal-opened {
  overflow: hidden;
}

.cmp-teaser.has-bg-image.has-media {
  height: 420px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: auto;
  background-size: initial;
  margin-top: -60px;
}

@media (min-width: 768px) {
  .cmp-teaser.has-bg-image.has-media {
    height: 475px;
    margin-top: -87px;
  }
}

.cmp-teaser.has-bg-image.has-media .cmp-teaser__title {
  color: var(--emu-semantic-colors-primary-blue-100);
  font-size: 32px;
  line-height: 1;
  font-weight: var(--emu-semantic-font-weight-extra-500);
  padding-top: var(--emu-common-spacing-xl);
}

@media (min-width: 768px) {
  .cmp-teaser.has-bg-image.has-media .cmp-teaser__title {
    padding-top: 208px;
    font-size: 46px;
    line-height: 51px;
  }
}

[data-component="text"], [data-component="title"] {
  color: var(--emu-semantic-colors-primary-purple-400);
  font: var(--emu-semantic-typography-narrow-body-regular);
}

[data-component="text"] p, [data-component="text"] li, [data-component="title"] p, [data-component="title"] li {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

[data-component="text"] sup, [data-component="text"] sub, [data-component="title"] sup, [data-component="title"] sub {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

[data-component="text"] sup, [data-component="title"] sup {
  top: -.5em;
}

[data-component="text"] sub, [data-component="title"] sub {
  top: -.25em;
}

[data-component="text"] b, [data-component="title"] b {
  font-weight: var(--emu-common-font-weight-bold);
}

/*# sourceMappingURL=main.css.map */
