#error-page {
  .error-text {
    max-width: 1055px; // needed to match live site. Value taken from live
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 164px;
    margin-bottom: 144px;

    h1,
    p {
      color: var(--emu-semantic-colors-primary-blue-300);
      font-weight: var(--emu-semantic-font-weight-extra-500);
      margin: var(--emu-common-spacing-none);
    }

    h1 {
      font-size: var(--emu-common-sizing-large);
      line-height: 70.4px;
    }

    p {
      font-size: var(--emu-common-sizing-medium);
      line-height: 40px;
    }
  }
}
