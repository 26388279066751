.emu-modal {
  &[data-component='modal'] {
    .modal-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      pointer-events: none;
      transition: all var(--emu-common-other-time-transition-base);

      &.is-open {
        opacity: 1;
        pointer-events: initial;

        .modal-content {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    .modal-content {
      border-radius: 6px;
      border: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-black);
      box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5); // used only once in the site
      width: auto;
      transition: all var(--emu-common-other-time-transition-base);
      opacity: 0;
      transform: translateY(-100px);
      margin: auto;
      position: relative;
      left: -6px;

      @include mq('medium') {
        width: var(--emu-component-layers-modal-body-sizing-max-width);
      }

      .aaaem-embed__oembed {
        padding: var(--emu-common-spacing-none);

        iframe {
          width: auto;
          height: auto;
          position: static;

          @include mq('medium') {
            width: 100%;
            height: 350px; // needed to match live site height
          }
        }
      }
    }

    .close {
      font-weight: var(--emu-semantic-font-weight-extra-500);
      opacity: 1;
      position: static;
      margin-left: auto;

      span {
        display: none;
      }

      &::after {
        content: 'X';
        display: block;
        font-size: 15px;
        line-height: var(--emu-common-line-heights-wide-large);
      }
    }
  }
}

.js-modal-opened {
  overflow: hidden;
}
